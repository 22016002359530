import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgencyAgentService {
  agencyDetail = new BehaviorSubject<any>(null);
  getAgencyDetail = this.agencyDetail.asObservable();

  agentDetail = new BehaviorSubject<any>(null);
  getAgentDetail = this.agentDetail.asObservable();

  isAgent = new BehaviorSubject<boolean>(null);
  getIsAgent = this.isAgent.asObservable();

  constructor() {}

  setAgencyDetail(data: any) {
    this.agencyDetail.next(data);
  }

  setIsAgent(data: boolean) {
    this.isAgent.next(data);
  }

  setAgentDetail(data: any) {
    this.agentDetail.next(data);
  }
 
}
