<!-- <app-header></app-header> -->
<section class="section-enrollment">
    <div class="container">
        <div class="enrlmnt-demographic">
            <div class="stepper">
                <div class="step active"></div>
                <div class="step active"></div>
                <div class="step active"></div>
            </div>
        </div>
        <div class="">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-12">
                        <div class="dependent-card">
                            <h6 class="form-title"><span>Employer Information</span></h6>
                            <section class="section-divider">
                                <div class="row">
                                    <div class="col-md-6 form-group has-float-label">
                                        <input class="form-control" type="text" autocomplete="off" readonly
                                            formControlName="companyname" placeholder=" " id="companyname">
                                        <label class="form-label form-control-placeholder" for="companyname"> Company
                                            Name/DBA </label>
                                    </div>
                                    <div class="col-md-6 form-group has-float-label">
                                        <input class="form-control" type="text" autocomplete="off" readonly
                                            formControlName="companyaddress" placeholder=" " id="companyaddress">
                                        <label class="form-label form-control-placeholder" for="companyaddress"> Company
                                            Address </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <p>
                                            <span class="text-danger"><sup>*</sup>Note:</span> You must complete this
                                            form
                                            in its entirety in order for you or your dependents to be
                                            covered under the employer’s group health plan. If
                                            you are waiving coverage for yourself or your dependents, it must be clearly
                                            indicated
                                            on
                                            this form. If you do not complete this form in
                                            its entirety for yourself or your dependents at least 5 business days prior
                                            to
                                            the
                                            effective
                                            date, you or your dependents may not be
                                            eligible for coverage until the next open enrollment period.
                                        </p>
                                    </div>
                                </div>
                            </section>
                            <section class="section-divider mt-4 pb-0">
                                <h6 class="form-subtitle ">
                                    Primary Applicant
                                </h6>
                                <div class="row">
                                    <div class="col-md-4 form-group has-float-label">
                                        <input maxlength="25" type="text" autocomplete="off"
                                            (focusout)="onFocusoutApplicant('firstname')"
                                            (blur)="primarytoempChange('firstname')" formControlName="firstname"
                                            class="form-control" placeholder=" " id="firstname">
                                        <label class="required form-control-placeholder" for="firstname">First Name
                                        </label>
                                        <small class="errormsg"
                                            *ngIf="!form.controls.firstname.valid && (form.controls.firstname.dirty || submitAttempt)">
                                            First Name is required
                                        </small>
                                    </div>
                                    <div class="col-md-4 form-group has-float-label">
                                        <input maxlength="25" type="text" autocomplete="off" id="middlename"
                                            (focusout)="onFocusoutApplicant('middlename')" formControlName="middlename"
                                            class="form-control" placeholder=" ">
                                        <label class="form-control-placeholder" for="middlename">Middle Name </label>
                                    </div>
                                    <div class="col-md-4 form-group has-float-label">
                                        <input maxlength="25" type="text" autocomplete="off"
                                            (blur)="primarytoempChange('lastname')" formControlName="lastname"
                                            (focusout)="onFocusoutApplicant('lastname')" class="form-control"
                                            placeholder=" " id="lastname">
                                        <label class="required form-control-placeholder" for="lastname">Last Name
                                        </label>
                                        <small class="errormsg"
                                            *ngIf="!form.controls.lastname.valid && (form.controls.lastname.dirty || submitAttempt)">
                                            Last Name is required
                                        </small>
                                    </div>
                                    <div class="col-md-4 form-group has-float-label">
                                        <input autocomplete="off" class="form-control" type="text" maxlength="11"
                                            (focusout)="onFocusoutApplicant('ssn')" socialSecurity formControlName="ssn"
                                            placeholder=" " (focus)="onFocusPrimary($event)" id="social-security"
                                            (blur)="primarytoempChange('ssn')" numbersOnly>

                                        <label class="form-control-placeholder" for="social-security"> Social Security
                                            Number </label>
                                        <span class="errormsg"
                                            *ngIf="form.get('ssn').errors?.invalidssn && (form.get('ssn').dirty ||form.get('ssn').touched )">
                                            Please
                                            enter valid 9 digit SSN number</span>
                                        <!-- <small class="errormsg"
                                            *ngIf="form.get('ssn').errors?.required && submitAttempt">
                                            Social Security Number is required
                                        </small> -->
                                    </div>
                                    <div class="col-md-4 form-group">
                                        <div class="p-float-label">
                                            <p-calendar placeholder=" "
                                                [style]="{'width': '100%','display': 'inline-flex','height':'50px'}"
                                                formControlName="dob" [maxDate]="minimumDate"
                                                (onSelect)="onSearchChange($event)" [disabledDates]="invalidDates1"
                                                [monthNavigator]="true" [yearNavigator]="true"
                                                [yearRange]="'1960:' + maxDate.getFullYear()" [showIcon]="true"
                                                inputId="icon" (onSelect)="onSelectDate(form.value, 'primary')"
                                                (onInput)="onEnterDate(form.value, 'primary')">
                                            </p-calendar>
                                            <label class="required form-control-placeholder"> Birth Date (mm/dd/yyyy)
                                            </label>
                                        </div>
                                        <small class="ageError">{{applicantAge}}</small>
                                        <small class="errormsg"
                                            *ngIf="!form.controls.dob.valid && (form.controls.dob.touched || submitAttempt)">
                                            DOB is required
                                        </small>
                                    </div>
                                    <div class="col-md-1  form-group has-float-label">
                                        <input autocomplete="off" placeholder=" " class="form-control"
                                            [attr.disabled]="disabledemployeeform ? '' : null" formControlName="age">
                                        <label class="form-control-placeholder">Age</label>
                                    </div>

                                    <div class="col-md-3 form-group ">
                                        <label class="mb-1">Gender</label>
                                        <div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" (blur)="changgender('gender')"
                                                    (change)="handleChange($event,'gender')" type="radio" value="Male"
                                                    formControlName="gender">
                                                <label class="form-check-label">
                                                    Male
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" (blur)="changgender('gender')"
                                                    (change)="handleChange($event,'gender')" type="radio" value="Female"
                                                    formControlName="gender">
                                                <label class="form-check-label">
                                                    Female
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" (blur)="changgender('gender')"
                                                    type="radio" value="Unspecified" formControlName="gender">
                                                <label class="form-check-label">
                                                    Unspecified
                                                </label>
                                            </div>
                                        </div>

                                        <!-- <small class="errormsg"
                                            *ngIf="!form.controls.gender.valid && (form.controls.gender.dirty || submitAttempt)">
                                            Gender is required
                                        </small> -->
                                    </div>
                                    <div class="col-md-4 form-group">
                                        <div class="p-float-label">
                                            <p-calendar placeholder=" " (onSelect)="changeemployedate()"
                                                [style]="{'width': '100%','display': 'inline-flex','height':'50px'}"
                                                formControlName="dateemployed" [maxDate]="minimumDate"
                                                [monthNavigator]="true" [yearNavigator]="true"
                                                [yearRange]="'1960:' + maxDate.getFullYear()" [showIcon]="true"
                                                inputId="icon">
                                            </p-calendar>
                                            <label class="form-control-placeholder"> Date employed Full-Time (mm/dd/yyyy)
                                            </label>
                                        </div>
                                        <!-- <small class="errormsg"
                                            *ngIf="!form.controls.dateemployed.valid && (form.controls.dateemployed.touched || submitAttempt)">
                                            Date employed Full-Time is required
                                        </small> -->
                                    </div>
                                    <div class="col-md-4 form-group has-float-label">

                                        <input maxlength="5" type="text" autocomplete="off"
                                            (focusout)="onFocusoutApplicant('avghrworkedperweek')"
                                            formControlName="avghrworkedperweek" class="form-control" numbersOnly
                                            placeholder=" " id="avghrworkedperweek">
                                        <label class="form-control-placeholder" for="avghrworkedperweek">Average number
                                            of hours worked per week? </label>
                                        <span class="input-group-text float-label-ip-text"
                                            id="basic-addon2">Hours</span>

                                    </div>
                                    <div class="col-md-4 form-group has-float-label">
                                        <input maxlength="25" type="text" autocomplete="off" formControlName="jobtitle"
                                            (focusout)="onFocusoutApplicant('jobtitle')" class="form-control"
                                            placeholder=" " id="jobtitle">
                                        <label class="form-control-placeholder" for="jobtitle">Job Title</label>
                                        <!-- <small class="errormsg"
                                            *ngIf="!form.controls.jobtitle.valid && (form.controls.jobtitle.dirty || submitAttempt)">
                                            Job Title is required
                                        </small> -->
                                    </div>
                                </div>
                            </section>
                            <section class="section-divider pb-0">
                                <h6 class="form-subtitle ">
                                    Address Details
                                </h6>
                                <div class="row">
                                    <div class="col-md-4 form-group has-float-label">
                                        <input class="form-control" formControlName="homeaddress"
                                            (focusout)="onFocusoutApplicant('homeaddress')"
                                            (blur)="InputChange('homeaddress')" type="text" autocomplete="off"
                                            placeholder=" " id="homeaddress">
                                        <label class="form-label form-control-placeholder" for="homeaddress"> Home
                                            Street Address </label>
                                    </div>
                                    <div class="col-md-3 form-group has-float-label">
                                        <input class="form-control" formControlName="homecity"
                                            (focusout)="onFocusoutApplicant('homecity')"
                                            (blur)="InputChange('homecity')" type="text" autocomplete="off"
                                            placeholder=" " id="city">
                                        <label class="form-label form-control-placeholder" for="city">City</label>
                                    </div>
                                    <div class="col-md-3 form-group has-float-label">
                                        <select formControlName="homestate" (blur)="InputChange('homestate')"
                                            (change)="handleChange($event,'homestate')"
                                            class="form-control form-select " aria-label="Default select example"
                                            id="state">
                                            <option disabled selected Value=""> Select State </option>
                                            <option [value]=item.name *ngFor="let item of usaStates">
                                                {{item.name}}
                                            </option>
                                        </select>
                                        <label class="form-label form-control-placeholder" for="state"> Select State
                                        </label>
                                    </div>
                                    <div class="col-md-2 form-group has-float-label">
                                        <input class="form-control" (blur)="InputChange('homezip')" maxlength="5"
                                            (focusout)="onFocusoutApplicant('homezip')" numbersOnly type="text"
                                            placeholder=" " formControlName="homezip" id="zipcode">
                                        <label class="form-label form-control-placeholder" for="zipcode"> Zip Code
                                        </label>
                                        <span class="errormsg"
                                            *ngIf="form.controls['homezip'].hasError('pattern') && (form.controls['homezip'].dirty )">Please
                                            enter valid 5 digit zip code</span>
                                    </div>
                                </div>
                                <div class="row">

                                    <div class="col-12">
                                        <h6 class="form-subtitle ">
                                            <span>Mailing Address</span>
                                        </h6>
                                    </div>

                                    <div class="col-md-12   mb-4">
                                        <div class="form-check">
                                            <input type="checkbox" formControlName="checkBox"
                                                (change)="checkBoXChange('value')" class="form-check-input">
                                            <label for="" class="form-label label-title">Mailing Address same as Home
                                                Address</label>
                                        </div>
                                    </div>

                                    <div class="col-md-4 form-group has-float-label">
                                        <input class="form-control" [attr.disabled]="disabled ? '' : null"
                                            (focusout)="onFocusoutApplicant('mailingaddress')"
                                            formControlName="mailingaddress" type="text" autocomplete="off"
                                            placeholder=" " id="mailingaddress">
                                        <label class="form-label form-control-placeholder" for="mailingaddress"> Mailing
                                            Address </label>
                                    </div>

                                    <div class="col-md-3 form-group has-float-label">
                                        <input class="form-control" [attr.disabled]="disabled ? '' : null"
                                            (focusout)="onFocusoutApplicant('mailingcity')"
                                            formControlName="mailingcity" type="text" autocomplete="off" placeholder=" "
                                            id="mailingcity">
                                        <label class="form-label form-control-placeholder" for="mailingcity">Mailing
                                            City</label>
                                    </div>

                                    <div class="col-md-3 form-group has-float-label">
                                        <select formControlName="mailingstate" [attr.disabled]="disabled ? '' : null"
                                            (change)="handleChange($event,'mailingstate')"
                                            class="form-select form-control" aria-label="Default select example">
                                            <option disabled selected Value="">Select Your State
                                            </option>
                                            <option [value]=item.name *ngFor="let item of usaStates">
                                                {{item.name}}
                                            </option>
                                        </select>
                                        <label class="form-label form-control-placeholder">Mailing State </label>
                                    </div>

                                    <div class="col-md-2 form-group has-float-label">
                                        <input class="form-control" maxlength="5" numbersOnly type="text"
                                            (focusout)="onFocusoutApplicant('mailingzip')"
                                            [attr.disabled]="disabled ? '' : null" placeholder=" "
                                            formControlName="mailingzip" id="mailingzip">
                                        <label class="form-label form-control-placeholder" for="mailingzip">Mailing Zip
                                            Code </label>
                                        <span class="errormsg"
                                            *ngIf="form.controls['mailingzip'].hasError('pattern') && (form.controls['mailingzip'].dirty )">Please
                                            enter valid 5 digit zip code</span>
                                    </div>
                                </div>
                            </section>
                            <section class="section-divider my-4">
                                <h6 class="form-title ">
                                    <span>Communication Details </span>
                                </h6>
                                <p class="d-none"><span class="text-danger"><sup>*</sup>Note:</span> Atleast 1 phone
                                    number is required
                                </p>
                                <div class="row">
                                    <ng-container formGroupName="phoneformgroup">
                                        <div class="col-md-3 form-group has-float-label">
                                            <input class="form-control" maxlength="12" formControlName="homephone"
                                                [value]="form.get('phoneformgroup').get('homephone').value"
                                                autocomplete="off" class="form-control" numbersOnly appPhonenumberValidation
                                                placeholder=" " id="homephone">
                                            <label class="form-label form-control-placeholder" for="homephone"> Home
                                                Phone </label>

                                            <span class="errormsg"
                                                *ngIf="form.get('phoneformgroup').get('homephone').errors?.invalidmobile && (form.get('phoneformgroup').get('homephone').dirty ||form.get('phoneformgroup').get('homephone').touched )">
                                                Please enter valid 10 digit phone number</span>
                                        </div>
                                        <div class="col-md-3 form-group has-float-label">
                                            <input class="form-control" maxlength="12" formControlName="workphone"
                                                [value]="form.get('phoneformgroup').get('workphone').value"
                                                (blur)="phonenumbervalidation(form.value.workphone)" autocomplete="off"
                                                class="form-control" numbersOnly appPhoneMask placeholder=" "
                                                id="workphone">

                                            <label class="form-label form-control-placeholder" for="workphone"> Work
                                                Phone</label>
                                            <span class="errormsg"
                                                *ngIf="form.get('phoneformgroup').get('workphone').errors?.invalidmobile && (form.get('phoneformgroup').get('workphone').dirty ||form.get('phoneformgroup').get('workphone').touched )">
                                                Please enter valid 10 digit phone number</span>
                                        </div>
                                        <div class="col-md-3 form-group has-float-label">
                                            <input class="form-control" maxlength="12" formControlName="cellphone"
                                                (blur)="phonenumbervalidation(form.value.cellphone)" autocomplete="off"
                                                [value]="form.get('phoneformgroup').get('cellphone').value"
                                                class="form-control" numbersOnly appPhoneMask placeholder=" "
                                                id="cellphone">

                                            <label class="form-label form-control-placeholder" for="cellphone">Cell
                                                Phone</label>
                                            <span class="errormsg"
                                                *ngIf="form.get('phoneformgroup').get('cellphone').errors?.invalidmobile && (form.get('phoneformgroup').get('cellphone').dirty ||form.get('phoneformgroup').get('cellphone').touched )">
                                                Please enter valid 10 digit phone number</span>
                                        </div>
                                    </ng-container>

                                    <div class="col-md-3 form-group">
                                        <div class="p-float-label">
                                            <p-calendar [timeOnly]="true" placeholder=" " [showTime]="true"
                                                (onSelect)="onSelectTime($event)" formControlName="besttimetocall"
                                                hourFormat="24"></p-calendar>
                                            <label class="form-label form-control-placeholder">Best Time to Call</label>
                                        </div>
                                    </div>

                                    <div class="col-md-3 form-group mb-0 has-float-label">
                                        <input class="form-control" formControlName="email" autocomplete="off"
                                            (focusout)="onFocusoutApplicant('email')" type="email" class="form-control"
                                            placeholder=" " id="email">
                                        <label class="form-control-placeholder" for="email">Email</label>
                                        <small class="errormsg"
                                            *ngIf="form.controls['email'].hasError('pattern') && (form.controls['email'].dirty || form.controls['email'].touched)">
                                            Please enter valid email address
                                        </small>
                                    </div>

                                </div>
                            </section>
                            <section class="">
                                <h6 class="form-title ">
                                    <span>Other Details</span>
                                </h6>
                                <div class="row">
                                    <div class="col-md-6 form-group ">
                                        <label class="form-label label-title d-block">Marital Status</label>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" value="Single"
                                                (change)="handleChange($event,'maritalstatus')"
                                                formControlName="maritalstatus">
                                            <label class="form-check-label">
                                                Single
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" value="Married"
                                                (change)="handleChange($event,'maritalstatus')"
                                                formControlName="maritalstatus">
                                            <label class="form-check-label">
                                                Married
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" value="Divorced"
                                                (change)="handleChange($event,'maritalstatus')"
                                                formControlName="maritalstatus">
                                            <label class="form-check-label">
                                                Divorced
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" value="Widowed"
                                                (change)="handleChange($event,'maritalstatus')"
                                                formControlName="maritalstatus">
                                            <label class="form-check-label">
                                                Widowed
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-6 form-group ">
                                        <label class="form-label label-title d-block"> Employee Status</label>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" value="W2"
                                                (change)="handleChange($event,'employeestatus')"
                                                formControlName="employeestatus">
                                            <label class="form-check-label">
                                                W2
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" value="1099"
                                                (change)="handleChange($event,'employeestatus')"
                                                formControlName="employeestatus">
                                            <label class="form-check-label">
                                                1099
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" value="Owner/Partner"
                                                (change)="handleChange($event,'employeestatus')"
                                                formControlName="employeestatus">
                                            <label class="form-check-label">
                                                Owner/Partner
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-6 ">
                                        <div class="row">
                                            <div class="col-md-12 form-group mb-1">
                                                <label class="form-label label-title d-block">Employeement Type </label>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" value="Full-Time"
                                                        (change)="handleChange($event,'statuscheck')"
                                                        formControlName="statuscheck" (change)="changeCheck($event)">
                                                    <span class="form-check-label">
                                                        Full-Time
                                                    </span>
                                                </div>

                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" value="Part-Time"
                                                        (change)="handleChange($event,'statuscheck')"
                                                        formControlName="statuscheck" (change)="changeCheck($event)">
                                                    <label class="form-check-label">
                                                        Part-Time
                                                    </label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" value="Retiree"
                                                        (change)="handleChange($event,'statuscheck')"
                                                        formControlName="statuscheck" (change)="changeCheck($event)">
                                                    <label class="form-check-label">
                                                        Retiree
                                                    </label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" value="COBRA"
                                                        (change)="handleChange($event,'statuscheck')"
                                                        formControlName="statuscheck" (change)="changeCheck($event)">
                                                    <label class="form-check-label">
                                                        COBRA
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="col-md-6 form-group">
                                                <div class="p-float-label">
                                                    <p-calendar placeholder="" (onSelect)="onSelectCobradate()"
                                                        [style]="{'width': '100%','display': 'inline-flex','height':'50px'}"
                                                        [disabled]="COBRAeffectivedate" formControlName="cobraeffectivedate"
                                                        [maxDate]="minimumDate" [monthNavigator]="true"
                                                        [yearNavigator]="true" [yearRange]="'1960:' + maxDate.getFullYear()"
                                                        [showIcon]="true" inputId="icon">
                                                    </p-calendar>
                                                    <label class="form-label"> COBRA effective date
                                                        :</label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label class="form-label label-title d-block"> Earnings Basis</label>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" formControlName="earningsbasis" type="radio"
                                                (change)="handleChange($event,'earningsbasis')" value="Salaried">
                                            <label class="form-check-label">
                                                Salaried </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" formControlName="earningsbasis" type="radio"
                                                (change)="handleChange($event,'earningsbasis')" value="Hourly">
                                            <label class="form-check-label">
                                                Hourly
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" formControlName="earningsbasis" type="radio"
                                                (change)="handleChange($event,'earningsbasis')" value="Commission">
                                            <label class="form-check-label">
                                                Commission
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <h6 class="form-subtitle"> Beneficiary Information : </h6>
                                <div class="row">
                                    <div class="col-md-3 form-group has-float-label">
                                        <input class="form-control" maxlength="25" type="text" autocomplete="off"
                                            (focusout)="onFocusoutApplicant('beneficiary_firstname')"
                                            formControlName="beneficiary_firstname" placeholder=" "
                                            id="beneficiary_firstname">
                                        <label class="form-label" for="beneficiary_firstname"> First Name </label>
                                    </div>

                                    <div class="col-md-3 form-group has-float-label">
                                        <input class="form-control" maxlength="25" type="text" autocomplete="off"
                                            (focusout)="onFocusoutApplicant('beneficiary_middlename')"
                                            formControlName="beneficiary_middlename" placeholder=" "
                                            id="beneficiary_middlename">
                                        <label class="form-label" for="beneficiary_middlename"> Middle Name </label>
                                    </div>

                                    <div class="col-md-3 form-group has-float-label">
                                        <input class="form-control" maxlength="25" type="text" autocomplete="off"
                                            (focusout)="onFocusoutApplicant('beneficiary_lastname')"
                                            formControlName="beneficiary_lastname" placeholder=" "
                                            id="beneficiary_lastname">
                                        <label class="form-label" for="beneficiary_lastname"> Last Name </label>
                                    </div>

                                    <div class="col-md-3 form-group has-float-label">
                                        <input class="form-control" maxlength="25" type="text" autocomplete="off"
                                            (focusout)="onFocusoutApplicant('beneficiary_relationship')"
                                            formControlName="beneficiary_relationship" placeholder=" "
                                            id="beneficiary_relationship">
                                        <label class="form-label" for="beneficiary_relationship"> Relationship </label>
                                    </div>

                                    <div class="col-md-4 form-group has-float-label">
                                        <input class="form-control" maxlength="25" type="text" autocomplete="off"
                                            (focusout)="onFocusoutApplicant('beneficiary_address1')"
                                            formControlName="beneficiary_address1" placeholder=" "
                                            id="beneficiary_address1">
                                        <label class="form-label" for="beneficiary_address1"> Address </label>
                                    </div>

                                    <div class="col-md-3 form-group has-float-label">
                                        <input class="form-control" type="text" formControlName="beneficiary_city"
                                            (focusout)="onFocusoutApplicant('beneficiary_city')" autocomplete="off"
                                            placeholder=" " id="beneficiary_city">
                                        <label class="form-label" for="beneficiary_city">City</label>
                                    </div>

                                    <div class="col-md-3 form-group has-float-label">
                                        <select class="form-control form-select" formControlName="beneficiary_state"
                                            (change)="handleChange($event,'beneficiary_state')"
                                            aria-label="Default select example" id="beneficiary_state">
                                            <option disabled Value="">Select Your State </option>
                                            <option [value]=item.name *ngFor="let item of usaStates">{{item.name}}
                                            </option>
                                        </select>
                                        <label class="form-label" for="beneficiary_state"> State </label>
                                    </div>

                                    <div class="col-md-2 form-group has-float-label">
                                        <input class="form-control" formControlName="beneficiary_zip" maxlength="5"
                                            (focusout)="onFocusoutApplicant('beneficiary_zip')" numbersOnly type="text"
                                            placeholder=" " id="beneficiary_zip">
                                        <label class="form-label" for="beneficiary_zip"> Zip Code </label>
                                        <span class="errormsg"
                                            *ngIf="form.controls['beneficiary_zip'].hasError('pattern') && (form.controls['beneficiary_zip'].dirty )">Please
                                            enter valid 5 digit zip code</span>
                                    </div>

                                </div>
                            </section>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="dependent-card">
                            <h6 class="form-title">New Enrollment or Waiver</h6>
                            <section class=" section-divider pb-0">
                                <label class="form-label label-italic"> Select one from the following</label>

                                <div class="row align-items-center">
                                    <div class="col-md-3 form-group ">
                                        <div class="form-check ">
                                            <input class="form-check-input" formControlName="newenrollment" type="radio"
                                                value="New-Hire" (change)="waiverofCoverage($event)" id="newenrollment">
                                            <label class="form-check-label mb-0" for=""> New-Hire </label>
                                        </div>
                                    </div>

                                    <div class="col-md-3 form-group">
                                        <div class="form-check ">
                                            <input class="form-check-input" formControlName="newenrollment" type="radio"
                                                value="Qualifying Life Event" (change)="waiverofCoverage($event)"
                                                id="QualifyingLifeEvent">
                                            <label class="form-check-label mb-0" for="QualifyingLifeEvent"> Qualifying
                                                Life Event </label>
                                        </div>
                                    </div>

                                    <div class="col-md-3 form-group has-float-label">
                                        <input class="form-control" type="text" autocomplete="off"
                                            [attr.disabled]="qualifyinglifeeventdisplay ? '' : null"
                                            (focusout)="onFocusoutApplicant('lifeevent')" formControlName="lifeevent"
                                            placeholder=" " id="lifeevent">
                                        <label class="form-check-label mb-0" for="lifeevent"> Qualifying Life Event
                                        </label>
                                    </div>

                                    <div class="col-md-3 form-group">
                                        <div class="p-float-label">
                                            <p-calendar placeholder="" (onSelect)="qualifyingdate($event)"
                                                [style]="{'width': '100%','display': 'inline-flex','height':'50px'}"
                                                formControlName="lifeeventdate" [maxDate]="minimumDate"
                                                [monthNavigator]="true" [yearNavigator]="true"
                                                [yearRange]="'1960:' + maxDate.getFullYear()" [showIcon]="true"
                                                inputId="icon" id="minimumDate">
                                            </p-calendar>
                                            <label for="" for="minimumDate">Life Event Date</label>
                                        </div>
                                    </div>

                                    <div class="col-md-3 form-group">
                                        <div class="form-check ">
                                            <input class="form-check-input" formControlName="newenrollment" type="radio"
                                                value="Re-hire" (change)="waiverofCoverage($event)" id="Re-hire">
                                            <label class="form-check-label mb-0" for="Re-hire">Re-hire </label>
                                        </div>
                                    </div>

                                    <div class="col-md-3 form-group">
                                        <div class="form-check ">
                                            <input class="form-check-input" formControlName="newenrollment" type="radio"
                                                value="COBRA" (change)="waiverofCoverage($event)" id="COBRA">
                                            <label class="form-check-label mb-0" for="COBRA"> COBRA </label>
                                        </div>
                                    </div>

                                    <div class="col-md-3 form-group">
                                        <div class="form-check  ">
                                            <input class="form-check-input" formControlName="newenrollment" type="radio"
                                                value="Open Enrollment" (change)="waiverofCoverage($event)"
                                                id="OpenEnrollment">
                                            <label class="form-check-label" for="OpenEnrollment"> Open Enrollment
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-md-3 form-group">
                                        <div class="form-check ">
                                            <input class="form-check-input" formControlName="newenrollment" type="radio"
                                                (change)="waiverofCoverage($event)" value="Waiver of Coverage"
                                                id="WaiverofCoverage">
                                            <label class="form-check-label" for="WaiverofCoverage">
                                                Waiver of Coverage (complete section B)
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-md-3 form-group">
                                        <div class="form-check ">
                                            <input class="form-check-input" formControlName="newenrollment" type="radio"
                                                value="New Group" (change)="waiverofCoverage($event)">
                                            <span class="form-check-label">
                                                New Group
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-3 form-group">
                                        <div class="form-check ">
                                            <input class="form-check-input" formControlName="newenrollment" type="radio"
                                                value="Other" (change)="waiverofCoverage($event)">
                                            <label class="form-check-label">
                                                Other
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-md-3 form-group has-float-label">
                                        <input class="form-control" type="text" autocomplete="off"
                                            (focusout)="onFocusoutApplicant('enrollment_other_description')"
                                            [attr.disabled]="enrollment_other_description ? '' : null"
                                            formControlName="enrollment_other_description" placeholder=" "
                                            id="enrollment_other_description">
                                        <label for="enrollment_other_description">Enter other information</label>
                                    </div>
                                </div>
                            </section>
                            <section class="section-divider pb-0 section-notapplicable mb-0 pt-4"
                                [ngClass]="{'section-notapplicable': waiverreasonedit, 'section-applicable': waiverreasonedit}">
                                <h6 class="form-title">Waiver Of Coverage (DO NOT COMPLETE IF ENROLLING FOR COVERAGE)
                                </h6>
                                <div class="row">
                                    <div class="col-md-12">
                                        <p>
                                            <span class="text-danger"><sup>*</sup>Note:</span>
                                            Complete and sign if waiving any or all coverages for self. All eligible
                                            employees
                                            must be listed as either enrolling or waiving coverage when first eligible
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group ">
                                    <label class="form-label label-italic"> Indicate the waiver reason below</label>
                                    <div class="row form-check-group">
                                        <div class="col-md-4 sub-form-group ">
                                            <div class="form-check">
                                                <input class="form-check-input" formControlName="waivercoverage"
                                                    (change)="handleChange($event,'waivercoverage')" type="radio"
                                                    value="Individual Medical"
                                                    [attr.disabled]="waiverreasonedit ? '' : null"
                                                    id="waiverreasonedit">
                                                <label class="form-check-label" for="waiverreasonedit">
                                                    Individual Medical
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4 sub-form-group ">
                                            <div class="form-check">
                                                <input class="form-check-input" formControlName="waivercoverage"
                                                    (change)="handleChange($event,'waivercoverage')" type="radio"
                                                    value="Medicare/Medicaid"
                                                    [attr.disabled]="waiverreasonedit ? '' : null" id="Medicare">
                                                <label class="form-check-label" for="Medicare">
                                                    Medicare/Medicaid
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4 sub-form-group ">
                                            <div class="form-check">
                                                <input class="form-check-input" formControlName="waivercoverage"
                                                    (change)="handleChange($event,'waivercoverage')" type="radio"
                                                    value="COBRA/Continuation"
                                                    [attr.disabled]="waiverreasonedit ? '' : null">
                                                <label class="form-check-label">
                                                    COBRA/Continuation
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4 sub-form-group ">
                                            <div class="form-check">
                                                <input class="form-check-input" formControlName="waivercoverage"
                                                    (change)="handleChange($event,'waivercoverage')" type="radio"
                                                    value="Tricare" [attr.disabled]="waiverreasonedit ? '' : null">
                                                <label class="form-check-label">
                                                    Tricare
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4 sub-form-group ">
                                            <div class="form-check">
                                                <input class="form-check-input" formControlName="waivercoverage"
                                                    (change)="handleChange($event,'waivercoverage')" type="radio"
                                                    value="Spouse’s/ParentEmployer Plan"
                                                    [attr.disabled]="waiverreasonedit ? '' : null">
                                                <label class="form-check-label">
                                                    Spouse’s/ParentEmployer Plan
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4 sub-form-group ">
                                            <div class="form-check">
                                                <input class="form-check-input" formControlName="waivercoverage"
                                                    type="radio" (change)="handleChange($event,'waivercoverage')"
                                                    value="Cost/Do not want (NO health coverage will exist)"
                                                    [attr.disabled]="waiverreasonedit ? '' : null">
                                                <label class="form-check-label">
                                                    Cost/Do not want (NO health coverage will exist)
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-3 sub-form-group ">
                                            <div class="form-check">
                                                <input class="form-check-input" formControlName="waivercoverage"
                                                    (change)="handleChange($event,'waivercoverage')" type="radio"
                                                    value="Other" [attr.disabled]="waiverreasonedit ? '' : null">
                                                <label class="form-check-label">
                                                    Other
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row ">

                                        <div class="col-md-12 mb-0 form-group has-float-label">
                                            <input class="form-control" type="text" autocomplete="off"
                                                (focusout)="onFocusoutApplicant('waivercoverageOther')"
                                                formControlName="waivercoverageOther"
                                                [attr.disabled]="waiverreasonedit ? '' : null" placeholder=" "
                                                id="waivercoverageOther">
                                            <label for="waivercoverageOther">Please mention the wavier reason (opted for
                                                others) :</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12  mb-3">
                                        <div class="form-check">
                                            <!-- <input class="form-check-input" type="checkbox" formControlName="covercheck" [attr.disabled]="waiverreasonedit ? '' : null"> //  future use -->
                                            <label class="form-check-label">
                                                Neither I nor my dependents have been induced or pressured to decline
                                                coverage by my employer, the agent, or Allstate Benefits. My dependents
                                                and
                                                I have waived such coverage of our own accord.
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-6 form-group has-float-label">
                                        <input class="form-control" type="text" autocomplete="off"
                                            (focusout)="onFocusoutApplicant('waiver_signature')"
                                            formControlName="waiver_signature" (blur)="signatureChange()"
                                            [attr.disabled]="waiverreasonedit ? '' : null" placeholder=" "
                                            id="Signature">
                                        <label class="form-label" for="Signature"> Signature</label>
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <div class="p-float-label">
                                            <p-calendar placeholder=" "
                                                [style]="{'width': '100%','display': 'inline-flex','height':'50px'}"
                                                [disabled]="waiverreasonedit" formControlName="signdate"
                                                [maxDate]="minimumDate" [monthNavigator]="true" [yearNavigator]="true"
                                                [yearRange]="'1960:' + maxDate.getFullYear()" [showIcon]="true"
                                                inputId="icon">
                                            </p-calendar>
                                            <label class="form-label " for="SignDate">Sign Date</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6 form-group has-float-label">
                                        <input class="form-control" type="text" autocomplete="off"
                                            formControlName="printedsignature"
                                            [attr.disabled]="signaturedisabled ? '' : null" placeholder=" "
                                            id="PrintedName">
                                        <label class="form-label" for="PrintedName"> Printed Name</label>
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <div class="p-float-label">
                                            <p-calendar placeholder=" "
                                                [style]="{'width': '100%','display': 'inline-flex','height':'50px'}"
                                                [disabled]="waiverreasonedit" formControlName="employeddate"
                                                [maxDate]="minimumDate" [monthNavigator]="true" [yearNavigator]="true"
                                                [yearRange]="'1960:' + maxDate.getFullYear()" [showIcon]="true"
                                                inputId="icon" id="employeddate">
                                            </p-calendar>
                                            <label class="form-label" for="employeddate"> Date employed Full-Time</label>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="col-12 pt-4"
                                [ngClass]="{'section-notapplicable': requestedeffective, 'section-applicable': waiverreasonedit}">
                                <!-- <section class="col-12" *ngIf="requestedeffectivedisplay"> // future use-->
                                <h6 class="form-title">Only to be completed by additions to existing groups or for
                                    changes to existing coverage</h6>
                                <section class="section-divider pb-0">
                                    <div class="row">
                                        <div class="col-md-4 form-group">
                                            <div class="p-float-label">
                                                <p-calendar placeholder=" "
                                                    [style]="{'display': 'inline-flex', 'width': '100%'}"
                                                    (onSelect)="onSelectRequestdate()" [disabled]="requestedeffective"
                                                    formControlName="requestdate" [minDate]="minimumDate"
                                                    [disabledDates]="invalidDates" [monthNavigator]="true"
                                                    [yearNavigator]="true" [yearRange]="'1960:' + maxDate.getFullYear()"
                                                    [showIcon]="true" inputId="icon" id="requestdate">
                                                </p-calendar>
                                                <label class="form-label">Requested effective date </label>
                                            </div>
                                            <small for="requestdate" class="form-help-text mt-2">(Subject to
                                                Underwriting
                                                approval)</small>
                                        </div>
                                    </div>
                                </section>
                                <section class="section-divider">
                                    <h6 class="form-subtitle ">
                                        Answer the following
                                    </h6>
                                    <div class="row  border-bottom mb-3">
                                        <div class="col-md-12 ">
                                            <label class="qstnr-label mb-3">
                                                <span class="qstnr-nmbr">1.</span>
                                                <span class="">Groups with multiple medical plans, indicate which plan
                                                    you are requesting?</span>
                                                <span class="f-500"> (Medical Plan)</span></label>
                                            <div class="form-group has-float-label">
                                                <input type="text" autocomplete="off"
                                                    formControlName="group_medical_plan"
                                                    (focusout)="onFocusoutApplicant('group_medical_plan')"
                                                    [disabled]="requestedeffective" class="form-control "
                                                    placeholder=" " id="group_medical_plan">
                                                <label for="group_medical_plan">Medical Plan</label>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row ">
                                        <div class="col-md-6 form-group">
                                            <label for="" class="qstnr-label d-block">
                                                <span class="qstnr-nmbr">2.</span>
                                                If dental coverage offered, are you electing?
                                            </label>

                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" formControlName="changedental"
                                                    type="radio" value="Yes" (change)="changedentalcoverage($event)"
                                                    [attr.disabled]="requestedeffective ? '' : null">
                                                <label class="form-check-label">
                                                    Yes
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" (change)="changedentalcoverage($event)"
                                                    formControlName="changedental" type="radio"
                                                    [attr.disabled]="requestedeffective ? '' : null" value="No">
                                                <label class="form-check-label">
                                                    No
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-6 form-group has-float-label" *ngIf="displaydentalans">

                                            <input type="text" autocomplete="off"
                                                (focusout)="onFocusoutApplicant('dental_coverage_list_enrolling')"
                                                formControlName="dental_coverage_list_enrolling"
                                                [attr.disabled]="requestedeffective ? '' : null" class="form-control "
                                                placeholder=" " id="dental_coverage_list_enrolling">

                                            <label for="dental_coverage_list_enrolling" class="">
                                                List those enrolling
                                            </label>
                                        </div>

                                        <div class="col-md-12 " *ngIf="displaydentalans">
                                            <label class="mb-3 f-400">If multiple dental plans are offered, which plan
                                                are you requesting? <small class="f-500">(Dental Plan)</small></label>
                                            <div class="form-group has-float-label">
                                                <input type="text" autocomplete="off" formControlName="dental_plan"
                                                    (focusout)="onFocusoutApplicant('dental_plan')"
                                                    [attr.disabled]="requestedeffective ? '' : null"
                                                    class="form-control " placeholder=" " id="dental_plan">
                                                <label for="dental_plan">Dental Plan</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row  border-top pt-3">
                                        <div class="col-md-6 form-group">
                                            <label for="" class="qstnr-label d-block">
                                                <span class="qstnr-nmbr">3.</span>
                                                If vision coverage offered, are youelecting?
                                            </label>

                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" formControlName="coverageoffered"
                                                    type="radio" value="Yes" (change)="changecoverageoffered($event)"
                                                    [attr.disabled]="requestedeffective ? '' : null">
                                                <label class="form-check-label">
                                                    Yes
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" (change)="changecoverageoffered($event)"
                                                    formControlName="coverageoffered" type="radio"
                                                    [attr.disabled]="requestedeffective ? '' : null" value="No">
                                                <label class="form-check-label">
                                                    No
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-6 form-group has-float-label" *ngIf="displaycoverageoffer">
                                            <input type="text" autocomplete="off"
                                                (focusout)="onFocusoutApplicant('vision_coverage_list_enrolling')"
                                                formControlName="vision_coverage_list_enrolling"
                                                [attr.disabled]="requestedeffective ? '' : null" class="form-control"
                                                placeholder=" " id="vision_coverage_list_enrolling">
                                            <label for="" class="qstnr-label" for="vision_coverage_list_enrolling">
                                                If yes, list those enrolling
                                            </label>
                                        </div>
                                    </div>

                                    <div class="row ">
                                        <div class="col-12 ">
                                            <p class="mb-0">
                                                <span class="text-danger"><sup>*</sup>Note:</span>
                                                Please contact your employer for the plan options/descriptions which
                                                are
                                                identified on your
                                                employer’s billing statement and/or quote
                                            </p>
                                        </div>
                                    </div>
                                </section>
                                <section class="">

                                    <div class="row">
                                        <div class="col-md-12 form-group">
                                            <label for="" class="qstnr-label">
                                                <span class="qstnr-nmbr">4.</span>
                                                If enrolling outside of your employer’s open enrollment period,
                                                indicate the
                                                special enrollment reason (documentation may be required)
                                            </label>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label for="" class="form-label label-title d-block"><span
                                                    class="f-600">a.</span>Event
                                                Type</label>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" formControlName="enrollingemployer"
                                                    type="radio" value="Marriage"
                                                    (change)="handleChange($event,'enrollingemployer')"
                                                    [attr.disabled]="requestedeffective ? '' : null">
                                                <label class="form-check-label">
                                                    Marriage
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" formControlName="enrollingemployer"
                                                    type="radio" value="Birth"
                                                    (change)="handleChange($event,'enrollingemployer')"
                                                    [attr.disabled]="requestedeffective ? '' : null">
                                                <label class="form-check-label">
                                                    Birth
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" formControlName="enrollingemployer"
                                                    type="radio" value="Adoption"
                                                    (change)="handleChange($event,'enrollingemployer')"
                                                    [attr.disabled]="requestedeffective ? '' : null">
                                                <label class="form-check-label">
                                                    Adoption
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" formControlName="enrollingemployer"
                                                    (change)="handleChange($event,'enrollingemployer')" type="radio"
                                                    value="Court ordered (copy of court order required)"
                                                    [attr.disabled]="requestedeffective ? '' : null">
                                                <label class="form-check-label">
                                                    Court ordered (copy of court order required)
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <div class="p-float-label">
                                                <p-calendar placeholder=""
                                                    [style]="{'display': 'inline-flex','height':'50px', 'width' : '100%'}"
                                                    [disabled]="requestedeffective" formControlName="date_of_event"
                                                    (onSelect)="onSelectdateevent()" [maxDate]="minimumDate"
                                                    [monthNavigator]="true" [yearNavigator]="true"
                                                    [yearRange]="'1960:' + maxDate.getFullYear()" [showIcon]="true"
                                                    inputId="icon" [attr.disabled]="requestedeffective ? '' : null">
                                                </p-calendar>
                                                <label class="form-label label-title">
                                                    For any event in a, list date of event
                                                </label>
                                            </div>
                                            <!-- <input type="date" autocomplete="off" formControlName=""  future use
                                                [attr.disabled]="requestedeffective ? '' : null" class="form-control"> -->
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="" class="form-label label-title"><span
                                                    class="f-600">b.</span>Event
                                                Type</label>
                                            <div class="form-check form-check-label">
                                                <input class="form-check-input"
                                                    formControlName="enrollingemployeroptionb" type="radio"
                                                    (change)="handleChange($event,'enrollingemployeroptionb')"
                                                    value="Divorce/Separation"
                                                    [attr.disabled]="requestedeffective ? '' : null">
                                                <label class="form-check-label">
                                                    Divorce/Separation
                                                </label>
                                            </div>
                                            <div class="form-check form-check-label">
                                                <input class="form-check-input"
                                                    formControlName="enrollingemployeroptionb" type="radio"
                                                    (change)="handleChange($event,'enrollingemployeroptionb')"
                                                    value="Involuntary loss of coverage, state reason for loss"
                                                    [attr.disabled]="requestedeffective ? '' : null"
                                                    id="enrollingemployeroptionb">
                                                <label class="form-check-label" for="loss_of_coverage">
                                                    Involuntary loss of coverage, state reason for loss
                                                </label>
                                                <input type="text" autocomplete="off" formControlName="loss_of_coverage"
                                                    (focusout)="onFocusoutApplicant('loss_of_coverage')"
                                                    [attr.disabled]="requestedeffective ? '' : null"
                                                    class="form-control form-control-sm" id="loss_of_coverage">
                                            </div>
                                            <div class="form-check form-check-label">
                                                <input class="form-check-input"
                                                    formControlName="enrollingemployeroptionb" type="radio"
                                                    (change)="handleChange($event,'enrollingemployeroptionb')"
                                                    value="COBRA/Continuation exhausted"
                                                    [attr.disabled]="requestedeffective ? '' : null"
                                                    id="Continuationexhausted">
                                                <label class="form-check-label" for="Continuationexhausted">
                                                    COBRA/Continuation exhausted
                                                </label>
                                            </div>
                                            <div class="form-check form-check-label">
                                                <input class="form-check-input"
                                                    formControlName="enrollingemployeroptionb" type="radio"
                                                    (change)="handleChange($event,'enrollingemployeroptionb')"
                                                    value="Other" [attr.disabled]="requestedeffective ? '' : null">
                                                <label class="form-check-label">
                                                    Other
                                                </label>
                                                <input type="text" autocomplete="off"
                                                    (focusout)="onFocusoutApplicant('special_enrollment_reason_description')"
                                                    formControlName="special_enrollment_reason_description"
                                                    class="form-control form-control-sm">
                                            </div>
                                        </div>
                                        <div class="col-md-6 form-group ">
                                            <label class="form-label label-title">For any event in b, list coverage
                                                termination date</label>
                                            <div class="p-float-label">
                                                <p-calendar placeholder=""
                                                    [style]="{'display': 'inline-flex','height':'50px', 'width' : '100%'}"
                                                    (onSelect)="onSelectterminationdate()"
                                                    [disabled]="requestedeffective" formControlName="termination_date"
                                                    [maxDate]="minimumDate" [monthNavigator]="true"
                                                    [yearNavigator]="true" [yearRange]="'1960:' + maxDate.getFullYear()"
                                                    [showIcon]="true" inputId="icon">
                                                </p-calendar>
                                                <label for="">Termination date</label>
                                            </div>
                                        </div>
                                        <div class="col-12 form-group mb-0">
                                            <label class="form-label ">
                                                <span class="text-danger"><sup>*</sup>Note:</span>
                                                Certificate of Creditable Coverage is required for all loss of
                                                coverage special enrollment events
                                            </label>
                                        </div>
                                    </div>
                                </section>
                            </section>
                        </div>
                    </div>
                </div>


                <div class="row mt-4">
                    <div class="col-lg-12">


                        <div class="dependent-card">
                            <section class="section-divider pb-0 section-notapplicable"
                                [ngClass]="{'section-notapplicable': waiverpersonscovered, 'section-applicable': waiverreasonedit}">
                                <h6 class="form-title mb-4"><span class="">Persons to be covered</span></h6>
                                <section class="section-divider pb-0">
                                    <div class="form-group ">

                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" value="Employee Only"
                                                [attr.disabled]="waiverpersonscovered ? '' : null"
                                                (change)="handleChange($event,'personscovered')"
                                                formControlName="personscovered" (change)="changePersons($event)">
                                            <label class="form-check-label">
                                                Employee Only
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" value="Employee Spouse"
                                                [attr.disabled]="waiverpersonscovered ? '' : null"
                                                (change)="handleChange($event,'personscovered')"
                                                formControlName="personscovered" (change)="changePersons($event)">
                                            <label class="form-check-label">
                                                Employee Spouse
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" value="Employee Child(ren)"
                                                [attr.disabled]="waiverpersonscovered ? '' : null"
                                                (change)="handleChange($event,'personscovered')"
                                                formControlName="personscovered" (change)="changePersons($event)">
                                            <label class="form-check-label">
                                                Employee Child(ren)
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"
                                                value="Family: Employee, Spouse, & Child(ren)"
                                                (change)="handleChange($event,'personscovered')"
                                                [attr.disabled]="waiverpersonscovered ? '' : null"
                                                formControlName="personscovered" (change)="changePersons($event)">
                                            <label class="form-check-label">
                                                Family: Employee, Spouse & Child(ren)
                                            </label>
                                        </div>
                                    </div>
                                    <!-- <small class="errormsg d-block" need this for future use
                                    *ngIf="!form.controls.personscovered.valid && (form.controls.personscovered.dirty || submitAttempt)">
                                    Field is required
                                </small> -->

                                </section>
                                <section class="section-divider pb-0" *ngIf="employeinfo">
                                    <div>
                                        <h6 class="form-subtitle"> Employee Information : </h6>
                                        <div class="row">
                                            <div class="col-md-3 form-group has-float-label has-float-label-readonly">
                                                <input class="form-control" maxlength="25"
                                                    [attr.disabled]="disabledemployeeform ? '' : null" type="text"
                                                    autocomplete="off" formControlName="empfirstname" placeholder=" "
                                                    id="empfirstname">
                                                <label class="form-label" for="empfirstname"> First Name </label>
                                            </div>
                                            <div class="col-md-3 form-group has-float-label has-float-label-readonly">
                                                <input class="form-control"
                                                    [attr.disabled]="disabledemployeeform ? '' : null" maxlength="25"
                                                    type="text" autocomplete="off" formControlName="emplastname"
                                                    placeholder=" " id="emplastname">
                                                <label class="form-label" for="emplastname"> Last Name </label>
                                            </div>
                                            <div class="col-md-3 form-group ">
                                                <label class="form-label mb-1">Gender</label>

                                                <div class="form-check-inline">
                                                    <input class="form-check-input" formControlName="empgender"
                                                        [attr.disabled]="disabledemployeeform ? '' : null" type="radio"
                                                        value="Male">
                                                    <span class="form-check-label">
                                                        Male
                                                    </span>
                                                </div>
                                                <div class="form-check-inline">
                                                    <input class="form-check-input" formControlName="empgender"
                                                        [attr.disabled]="disabledemployeeform ? '' : null" type="radio"
                                                        value="Female">
                                                    <span class="form-check-label">
                                                        Female
                                                    </span>
                                                </div>
                                                <div class="form-check-inline">
                                                    <input class="form-check-input" formControlName="empgender"
                                                        [attr.disabled]="disabledemployeeform ? '' : null" type="radio"
                                                        value="Unspecified">
                                                    <span class="form-check-label">
                                                        Unspecified
                                                    </span>
                                                </div>

                                            </div>
                                            <div class="col-md-3 form-group has-float-label">
                                                <p-calendar placeholder=" "
                                                    [style]="{'width': '100%','display': 'inline-flex','height':'50px'}"
                                                    formControlName="empdob" [maxDate]="minimumDate"
                                                    [disabled]="disabledemployeeform" [monthNavigator]="true"
                                                    [yearNavigator]="true" [yearRange]="'1960:' + maxDate.getFullYear()"
                                                    [showIcon]="true" inputId="icon">
                                                </p-calendar>
                                                <label class="form-label"> Birth Date (mm/dd/yyyy) </label>
                                            </div>
                                            <div class="col-md-3 form-group has-float-label has-float-label-readonly">
                                                <input autocomplete="off" class="form-control" type="text"
                                                    maxlength="11" socialSecurity formControlName="empssn"
                                                    placeholder=" " numbersOnly
                                                    (focusout)="onprimaryssnFocusout($event)"
                                                    [attr.disabled]="disabledemployeeform ? '' : null"
                                                    (focus)="onFocusPrimary($event)" id="social-security">
                                                <label class="form-label" for="social-security"> Social Security Number
                                                </label>
                                                <span class="errormsg"
                                                    *ngIf="form.get('ssn').errors?.invalidssn && (form.get('ssn').dirty ||form.get('ssn').touched )">
                                                    Please
                                                    enter valid 9 digit SSN number</span>
                                                <!-- <small class="errormsg"
                                                    *ngIf="form.get('ssn').errors?.required && submitAttempt">
                                                    Social Security Number is required
                                                </small> -->
                                            </div>
                                            <div class="col-md-2 form-group ">
                                                <label class="form-label">Tobacco Use</label>

                                                <div class="form-check-inline">
                                                    <input class="form-check-input" type="radio" value="Yes"
                                                        [attr.disabled]="waiverpersonscovered ? '' : null"
                                                        (change)="handleChange($event,'emptobaccouse')"
                                                        formControlName="emptobaccouse">
                                                    <span class="form-check-label">
                                                        Yes
                                                    </span>
                                                </div>
                                                <div class="form-check-inline">
                                                    <input class="form-check-input" type="radio" value="No"
                                                        [attr.disabled]="waiverpersonscovered ? '' : null"
                                                        (change)="handleChange($event,'emptobaccouse')"
                                                        formControlName="emptobaccouse">
                                                    <span class="form-check-label">
                                                        No
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="section-divider pb-0" *ngIf="spouseinfo">
                                    <div>
                                        <h6 class="form-subtitle"> Spouse Information : </h6>
                                        <div class="row">
                                            <div class="col-md-3 form-group has-float-label ">
                                                <input class="form-control" maxlength="25" type="text"
                                                    autocomplete="off" formControlName="spousefirstname" placeholder=" "
                                                    id="spousefirstname">
                                                <label class="form-label required" for="spousefirstname"> First Name
                                                </label>
                                                <small class="errormsg"
                                                    *ngIf="!form.controls.spousefirstname.valid && (form.controls.spousefirstname.dirty || submitAttempt)">
                                                    First Name is required
                                                </small>
                                            </div>
                                            <div class="col-md-3 form-group has-float-label">
                                                <input class="form-control" maxlength="25" type="text"
                                                    autocomplete="off" formControlName="spouselastname" placeholder=" "
                                                    id="spouselastname">
                                                <label class="form-label required" for="spouselastname"> Last Name
                                                </label>
                                                <small class="errormsg"
                                                    *ngIf="!form.controls.spouselastname.valid && (form.controls.spouselastname.dirty || submitAttempt)">
                                                    Last Name is required
                                                </small>
                                            </div>
                                            <div class="col-md-3 form-group has-float-label">
                                                <input class="form-control" maxlength="25" type="email"
                                                    autocomplete="off" formControlName="spouseemail" placeholder=" "
                                                    id="spouseemail">
                                                <label class="form-label required" for="spouseemail"> Email </label>
                                                <small class="errormsg"
                                                    *ngIf="form.controls['spouseemail'].hasError('pattern') && (form.controls['spouseemail'].dirty || form.controls['spouseemail'].touched)">
                                                    Please enter valid email address
                                                </small>
                                            </div>
                                            <div class="col-md-3 form-group has-float-label">
                                                <input class="form-control" maxlength="12" formControlName="spousephone"
                                                    autocomplete="off"
                                                    [value]="form.get('spousephone').value"
                                                    class="form-control" numbersOnly appPhoneMask placeholder=" "
                                                    id="spousephone">
                                                <label class="form-label form-control-placeholder"
                                                    for="spousephone">Phone</label>
                                                <span class="errormsg"
                                                    *ngIf="form.get('spousephone').errors?.invalidmobile && (form.get('spousephone').dirty ||form.get('spousephone').touched )">
                                                    Please enter valid 10 digit phone number</span>
                                            </div>
                                            <div class="col-md-3 form-group ">
                                                <label class="form-label mb-1">Gender</label>

                                                <div class="form-check-inline">
                                                    <input class="form-check-input" formControlName="spousegender"
                                                        type="radio" value="Male">
                                                    <span class="form-check-label">
                                                        Male
                                                    </span>
                                                </div>

                                                <div class="form-check-inline">
                                                    <input class="form-check-input" formControlName="spousegender"
                                                        type="radio" value="Female">
                                                    <span class="form-check-label">
                                                        Female
                                                    </span>
                                                </div>

                                                <div class="form-check-inline">
                                                    <input class="form-check-input" formControlName="spousegender"
                                                        type="radio" value="Unspecified">
                                                    <span class="form-check-label">
                                                        Unspecified
                                                    </span>
                                                </div>

                                            </div>

                                            <div class="col-md-3 form-group">
                                                <div class="p-float-label">
                                                    <p-calendar placeholder=" "
                                                        [style]="{'width': '100%','display': 'inline-flex','height':'50px'}"
                                                        formControlName="spousedob" [maxDate]="minimumDate"
                                                        [disabledDates]="invalidDates1" [monthNavigator]="true"
                                                        [yearNavigator]="true" [yearRange]="'1960:' + maxDate.getFullYear()"
                                                        [showIcon]="true" inputId="icon"
                                                        (onSelect)="onSelectDate(form.value, 'spouse')"
                                                        (onInput)="onEnterDate(form.value, 'spouse')">
                                                    </p-calendar>
                                                    <label class="form-label required"> Birth Date (mm/dd/yyyy) </label>
                                                </div>
                                                <small class="errormsg"
                                                    *ngIf="!form.controls.spousedob.valid && (form.controls.spousedob.dirty || submitAttempt)">
                                                    DOB is required
                                                </small>
                                                <small class="ageError d-block">{{spouseAge}}</small>
                                            </div>

                                            <div class="col-md-1  form-group has-float-label">
                                                <input autocomplete="off" placeholder=" " class="form-control"
                                                    [attr.disabled]="disabledemployeeform ? '' : null"
                                                    formControlName="spouseage" id="spouseage">
                                                <label class="" for="spouseage">Age</label>
                                            </div>

                                            <div class="col-md-3 form-group has-float-label ">
                                                <input autocomplete="off" class="form-control" type="text"
                                                    maxlength="11" socialSecurity formControlName="spousessn"
                                                    placeholder=" " (focus)="onFocusPrimary($event)"
                                                    id="spouse-security" numbersOnly id="spouse-security">
                                                <label class="form-label" for="spouse-security"> Social Security Number
                                                </label>

                                                <!-- <span class="errormsg"
                                                    *ngIf="form.get('spousessn').errors?.invalidssn && (form.get('spousessn').dirty ||form.get('spousessn').touched )">
                                                    Please
                                                    enter valid 9 digit SSN number</span> -->
                                            </div>

                                            <div class="col-md-2 form-group ">
                                                <label class="form-label">Tobacco Use</label>
                                                <div class="form-check-inline">
                                                    <input class="form-check-input" type="radio" value="Yes"
                                                        formControlName="spousetobaccouse">
                                                    <span class="form-check-label">
                                                        Yes
                                                    </span>
                                                </div>
                                                <div class="form-check-inline">
                                                    <input class="form-check-input" type="radio" value="No"
                                                        formControlName="spousetobaccouse">
                                                    <span class="form-check-label">
                                                        No
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="section-divider pb-0" *ngIf="childinfo">
                                    <div class="row">
                                        <div formArrayName="child_Array" class="col-lg-12">
                                            <div *ngFor="let childForm of child_Array().controls; let empIndex=index"
                                                mb-4>
                                                <div [formGroupName]="empIndex">
                                                    <div class="">
                                                        <h6 class="form-subtitle"> Child - {{empIndex +1}} Information:
                                                        </h6>
                                                        <div class="row">
                                                            <div class="col-md-3 form-group has-float-label">
                                                                <input class="form-control" maxlength="25"
                                                                    autocomplete="off" type="text" placeholder=" "
                                                                    formControlName="childFirstName"
                                                                    id="childFirstName">
                                                                <label class="form-label required" for="childFirstName">
                                                                    First Name </label>
                                                                <small class="errormsg"
                                                                    *ngIf="childForm.get('childFirstName')?.errors?.['required'] &&  (childForm.get('childFirstName')?.touched || submitAttempt)">
                                                                    First Name is required
                                                                </small>
                                                            </div>
                                                            <div class="col-md-3 form-group has-float-label">
                                                                <input class="form-control" maxlength="25"
                                                                    autocomplete="off" type="text" placeholder=" "
                                                                    formControlName="childLastName" id="childLastName">
                                                                <label class="form-label required" for="childLastName">
                                                                    Last Name </label>
                                                                <small class="errormsg"
                                                                    *ngIf="childForm.get('childLastName')?.errors?.['required'] &&  (childForm.get('childLastName')?.touched || submitAttempt)">
                                                                    Last Name is required
                                                                </small>
                                                            </div>
                                                            <div class="col-md-3 form-group has-float-label">
                                                                <input class="form-control" maxlength="25" type="email"
                                                                    autocomplete="off" formControlName="childEmail"
                                                                    placeholder=" " id="childEmail">
                                                                <label class="form-label" for="childEmail"> Email
                                                                </label>
                                                                <small class="errormsg"
                                                                    *ngIf="childForm.get('childEmail')?.errors?.['pattern'] &&  (childForm.get('childEmail')?.touched || submitAttempt)">
                                                                    Please enter valid email address
                                                                </small>
                                                            </div>
                                                            <div class="col-md-3 form-group has-float-label">
                                                                <input class="form-control" maxlength="12"
                                                                    formControlName="childPhone" autocomplete="off"
                                                                    class="form-control" numbersOnly appPhoneMask
                                                                    placeholder=" " id="childPhone">
                                                                <label class="form-label form-control-placeholder"
                                                                    for="childPhone">Phone</label>
                                                                <span class="errormsg"
                                                                    *ngIf="childForm.get('childPhone')?.errors?.['invalidmobile'] && ( childForm.get('childPhone')?.dirty || childForm.get('childPhone')?.touched )">
                                                                    Please enter valid 10 digit phone number</span>
                                                            </div>
                                                            <div class="col-md-3 form-group ">
                                                                <label class="form-label mb-1">Gender</label>

                                                                <div class="form-check-inline">
                                                                    <input class="form-check-input" type="radio"
                                                                        value="Male" formControlName="childGender">
                                                                    <span class="form-check-label">
                                                                        Male
                                                                    </span>
                                                                </div>
                                                                <div class="form-check-inline">
                                                                    <input class="form-check-input" type="radio"
                                                                        value="Female" formControlName="childGender">
                                                                    <span class="form-check-label">
                                                                        Female
                                                                    </span>
                                                                </div>
                                                                <div class="form-check-inline">
                                                                    <input class="form-check-input" type="radio"
                                                                        value="Unspecified"
                                                                        formControlName="childGender">
                                                                    <span class="form-check-label">
                                                                        Unspecified
                                                                    </span>
                                                                </div>

                                                            </div>
                                                            <div class="col-lg-3 form-group">
                                                                <div class="p-float-label">
                                                                    <p-calendar placeholder=" "
                                                                        [style]="{'width': '100%','display': 'inline-flex','height':'50px'}"
                                                                        formControlName="childDob" [maxDate]="minimumDate"
                                                                        [disabledDates]="invalidDates1"
                                                                        [monthNavigator]="true" [yearNavigator]="true"
                                                                        [yearRange]="'1994:'+ maxDate.getFullYear()"
                                                                        [showIcon]="true" inputId="icon"
                                                                        (onSelect)="onSelectChildDate(empIndex)">
                                                                    </p-calendar>
                                                                    <label class="form-label required">Birth Date
                                                                        (mm/dd/yyyy)
                                                                    </label>
                                                                </div>
                                                                <small class="errormsg"
                                                                    *ngIf="childForm.get('childDob')?.errors?.['required'] &&  (childForm.get('childDob')?.touched || submitAttempt)">
                                                                    DOB is required
                                                                </small>
                                                            </div>
                                                            <div class="col-md-1  form-group has-float-label">
                                                                <input autocomplete="off" placeholder=" "
                                                                    class="form-control"
                                                                    [attr.disabled]="disabledemployeeform ? '' : null"
                                                                    formControlName="childage" id="childage">
                                                                <label class="" for="childage">Age</label>
                                                            </div>
                                                            <div class="col-md-3 form-group has-float-label">
                                                                <input class="form-control" class="form-control"
                                                                    type="text" maxlength="11"
                                                                    attr.id="social-security-child-{{empIndex}}"
                                                                    placeholder=" " formControlName="childSsn"
                                                                    socialSecurity id="childSsn">
                                                                <label class="form-label" for="childSsn"> Social
                                                                    Security Number </label>

                                                                <span class="errormsg"
                                                                    *ngIf="childForm.get('childSsn')?.errors?.['invalidssn'] && ( childForm.get('childSsn')?.dirty || childForm.get('childSsn')?.touched )">
                                                                    Please enter valid 9 digit SSN number
                                                                </span>

                                                            </div>
                                                            <div class="col-md-3 form-group ">
                                                                <label class="form-label mb-1">Tobacco Use</label>
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" type="radio"
                                                                        value="Yes" formControlName="childTobaccouse">
                                                                    <label class="form-check-label"> Yes </label>
                                                                </div>
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" type="radio"
                                                                        value="No" formControlName="childTobaccouse">
                                                                    <label class="form-check-label"> No </label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="col-md-12 text-end">
                                                            <button type="submit" class="btn-remove mb-3"
                                                                *ngIf="empIndex > 0"
                                                                (click)="removeEmployee(empIndex)"><i
                                                                    class="ri-user-unfollow-line"></i>
                                                                Remove Child</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="section-divider pb-0" *ngIf="childinfo">
                                    <div class="add-members-section mb-2">
                                        <div class="button-group">
                                            <button type="submit" class=" btn-add btn-member " (click)="addNewForm()"><i
                                                    class="ri-user-add-line"></i>
                                                Add Children </button>
                                        </div>
                                    </div>

                                </section>

                                <h6 class="form-subtitle"> Additional insurance coverage information </h6>

                                <div class="row">
                                    <div class="col-md-12 form-group ">
                                        <label class="form-label label-title d-block"> 1. Will any current medical plan remain
                                            active if
                                            coverage is approved?</label>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" value="Yes"
                                                [attr.disabled]="waiverpersonscovered ? '' : null"
                                                formControlName="currentmedicalplan"
                                                (change)="medicalplanCheck($event)">
                                            <label class="form-check-label">
                                                Yes
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" value="No"
                                                [attr.disabled]="waiverpersonscovered ? '' : null"
                                                formControlName="currentmedicalplan"
                                                (change)="medicalplanCheck($event)">
                                            <label class="form-check-label">
                                                No
                                            </label>
                                        </div>

                                    </div>
                                </div>

                                <div class="row" *ngIf="displaymedicalplanAns">
                                    <div class="col-md-6 form-group">
                                        <label for="current_medical_plan_remain_active_description"
                                            class="form-label">a) If “Yes”, for whom?</label>
                                        <input type="text" autocomplete="off"
                                            (focusout)="onFocusoutApplicant('current_medical_plan_remain_active_description')"
                                            formControlName="current_medical_plan_remain_active_description"
                                            class="form-control" id="current_medical_plan_remain_active_description">
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label for="" class="form-label">
                                            b) Please provide carrier and ID/Group number
                                        </label>
                                        <input type="text" autocomplete="off"
                                            (focusout)="onFocusoutApplicant('active_carrier_id')"
                                            formControlName="active_carrier_id" class="form-control">
                                    </div>
                                </div>

                                <div class="row ">
                                    <div class="col-12 form-group border-top pt-3 ">
                                        <label class="form-label label-title d-block"> 2. Are you, your spouse or any dependent
                                            children
                                            currently covered under Medicare Part A, B, or D?</label>

                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" value="Yes"
                                                [attr.disabled]="waiverpersonscovered ? '' : null"
                                                formControlName="medicalpart" (change)="medicalpartCheck($event)">
                                            <label class="form-check-label">
                                                Yes
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" value="No"
                                                [attr.disabled]="waiverpersonscovered ? '' : null"
                                                formControlName="medicalpart" (change)="medicalpartCheck($event)">
                                            <label class="form-check-label">
                                                No
                                            </label>
                                        </div>

                                    </div>
                                </div>

                                <div class="row" *ngIf="displaymedicarepartAns">
                                    <div class="form-group col-md-6">
                                        <label for="" class="form-label">a) If “Yes”, for whom?</label>
                                        <input type="text" autocomplete="off"
                                            (focusout)="onFocusoutApplicant('covered_under_medicare_description')"
                                            formControlName="covered_under_medicare_description" class="form-control">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="" class="form-label">
                                            b) If yes, will coverage remain active if the coverage for which you are
                                            applying is approved?
                                        </label>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" value="Yes"
                                                (change)="handleChange($event,'covered_under_medicare_active')"
                                                formControlName="covered_under_medicare_active">
                                            <label class="form-check-label">
                                                Yes
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" value="No"
                                                (change)="handleChange($event,'covered_under_medicare_active')"
                                                formControlName="covered_under_medicare_active">
                                            <label class="form-check-label">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <h6 class="form-title mb-4">Medical History : </h6>
                                    <div class="col-12 border-bottom mb-3 pb-3" *ngIf="employeinfo">

                                        <h6 class="form-subtitle">Employee Medical History : </h6>

                                        <div class="row">
                                            <div class="col-md-4 form-group">
                                                <label class="form-label">Height</label>
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div class="input-group ">
                                                            <select formControlName="empfeet" class="form-select"
                                                                (change)="handleChange($event,'gender')"
                                                                [attr.disabled]="waiverpersonscovered ? '' : null"
                                                                aria-label="Default select example">
                                                                <option value="" disabled>Feet</option>
                                                                <option value={{item.feet}}
                                                                    *ngFor="let item of feetArray | slice:1">
                                                                    {{item.feet}} </option>
                                                            </select>
                                                            <span class="input-group-text" id="basic-addon2">Ft</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="input-group ">
                                                            <select formControlName="empinch" class="form-select"
                                                                [attr.disabled]="waiverpersonscovered ? '' : null"
                                                                aria-label="Default select example">
                                                                <option value="" disabled>Inches</option>
                                                                <option value={{item.inchValue}}
                                                                    *ngFor="let item of inchArray; let i=index">
                                                                    {{item.inch}} </option>
                                                            </select>
                                                            <span class="input-group-text" id="basic-addon2">In</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-2 form-group ">
                                                <label class="form-label">Weight</label>
                                                <div class="input-group ">
                                                    <input class="form-control" formControlName="empweight"
                                                        (focusout)="onFocusoutApplicant('empweight')"
                                                        [attr.disabled]="waiverpersonscovered ? '' : null" type="text"
                                                        numbersOnly autocomplete="off" maxlength="3"
                                                        placeholder="Weight">
                                                    <span class="input-group-text " id="basic-addon2">Lbs</span>
                                                </div>
                                                <small class="errormsg"
                                                    *ngIf="!form.controls['empweight'].valid && (form.controls['empweight'].touched || submitAttempt)">
                                                    Weight Should not be more than 600
                                                </small>
                                            </div>

                                            <div class="col-md-6 form-group ">
                                                <label class=" mb-3">Own a Motorcycle?</label>

                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" value="Yes"
                                                        [attr.disabled]="waiverpersonscovered ? '' : null"
                                                        (change)="handleChange($event,'empmotercycle')"
                                                        formControlName="empmotercycle">
                                                    <span class="form-check-label">
                                                        Yes
                                                    </span>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" value="No"
                                                        (change)="handleChange($event,'empmotercycle')"
                                                        [attr.disabled]="waiverpersonscovered ? '' : null"
                                                        formControlName="empmotercycle">
                                                    <span class="form-check-label">
                                                        No
                                                    </span>
                                                </div>
                                            </div>


                                            <div class="col-md-6 form-group ">
                                                <label class="">Convicted of a moving violation in the last
                                                    year?</label>

                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" value="Yes"
                                                        [attr.disabled]="waiverpersonscovered ? '' : null"
                                                        (change)="handleChange($event,'empmovingviolation')"
                                                        formControlName="empmovingviolation">
                                                    <span class="form-check-label">
                                                        Yes
                                                    </span>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" value="No"
                                                        (change)="handleChange($event,'empmovingviolation')"
                                                        [attr.disabled]="waiverpersonscovered ? '' : null"
                                                        formControlName="empmovingviolation">
                                                    <span class="form-check-label">
                                                        No
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="col-md-6 form-group ">
                                                <label class="">Convicted of a DUI/OWI in the last 5
                                                    years?</label>

                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" value="Yes"
                                                        [attr.disabled]="waiverpersonscovered ? '' : null"
                                                        (change)="handleChange($event,'empduiowi')"
                                                        formControlName="empduiowi">
                                                    <span class="form-check-label">
                                                        Yes
                                                    </span>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" value="No"
                                                        (change)="handleChange($event,'empduiowi')"
                                                        [attr.disabled]="waiverpersonscovered ? '' : null"
                                                        formControlName="empduiowi">
                                                    <span class="form-check-label">
                                                        No
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-12 border-bottom mb-3 pb-3" *ngIf="spouseinfo">
                                        <h6 class="form-subtitle"> Spouse Medical History : </h6>
                                        <div class="row">
                                            <div class="col-md-4 ">
                                                <label class="form-label">Height</label>
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div class="input-group ">
                                                            <select formControlName="spousefeet" class="form-select"
                                                                aria-label="Default select example">
                                                                <option value="" disabled>Feet</option>
                                                                <option value={{item.feet}}
                                                                    *ngFor="let item of feetArray | slice:1">
                                                                    {{item.feet}} </option>
                                                            </select>
                                                            <span class="input-group-text" id="basic-addon2">Ft</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="input-group ">
                                                            <select formControlName="spouseinch" class="form-select"
                                                                aria-label="Default select example">
                                                                <option value="" disabled>Inches</option>
                                                                <option value={{item.inchValue}}
                                                                    *ngFor="let item of inchArray; let i=index">
                                                                    {{item.inch}} </option>
                                                            </select>
                                                            <span class="input-group-text" id="basic-addon2">In</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-md-2 form-group ">
                                                <label class="form-label">Weight</label>
                                                <input class="form-control" formControlName="spouseweight" type="text"
                                                    numbersOnly autocomplete="off" maxlength="3" placeholder="Weight">
                                                <span class="input-group-text float-label-ip-text"
                                                    id="basic-addon2">Lbs</span>
                                                <small class="errormsg"
                                                    *ngIf="!form.controls['spouseweight'].valid && (form.controls['spouseweight'].touched || submitAttempt)">
                                                    Weight Should not be more than 600
                                                </small>
                                            </div>

                                            <div class="col-md-6 form-group ">
                                                <label class="">Own a Motorcycle?</label>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" value="Yes"
                                                        formControlName="spousemotercycle">
                                                    <label class="form-check-label">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" value="No"
                                                        formControlName="spousemotercycle">
                                                    <label class="form-check-label">
                                                        No
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="col-md-6 form-group ">
                                                <label class="">Convicted of a moving violation in the last
                                                    year?</label>

                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" value="Yes"
                                                        formControlName="spousemovingviolation">
                                                    <span class="form-check-label">
                                                        Yes
                                                    </span>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" value="No"
                                                        formControlName="spousemovingviolation">
                                                    <span class="form-check-label">
                                                        No
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="col-md-6 form-group ">
                                                <label class="">Convicted of a DUI/OWI in the last 5
                                                    years?</label>

                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" value="Yes"
                                                        formControlName="spouseduiowi">
                                                    <span class="form-check-label">
                                                        Yes
                                                    </span>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" value="No"
                                                        formControlName="spouseduiowi">
                                                    <span class="form-check-label">
                                                        No
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngFor="let qtns of healthQuestions; let i=index">
                                    <div class="col-12 ">
                                        <div class="row">
                                            <div class="col-md-12 form-group">
                                                <label class="form-label label-title">
                                                    {{i+1}}. {{qtns.name}}
                                                </label>
                                                <ng-container *ngIf="qtns.health_issue.length > 0">
                                                    <div class="form-check form-check-inline "
                                                        *ngFor="let issue of qtns.health_issue; let el=index">
                                                        <input class="form-check-input" type="radio"
                                                            [name]="issue.health_issue_id"
                                                            [value]="issue.health_issue_id"
                                                            [(ngModel)]="qtns.selected_val"
                                                            [ngModelOptions]="{standalone: true}"
                                                            [attr.disabled]="waiverpersonscovered ? '' : null"
                                                            (change)="medicalHistoryDetails($event , qtns, i, issue.value)">
                                                        <span class="form-check-label">
                                                            {{issue.value}}
                                                        </span>
                                                    </div>

                                                </ng-container>
                                                <ng-container
                                                    *ngIf="!!qtns.sub_questions && qtns.sub_questions.length > 0">
                                                    <div class="row ps-4">
                                                        <div class="col-md-6 form-group"
                                                            *ngFor="let sub_ques of qtns.sub_questions ; let i=index">
                                                            <label class="form-label">
                                                                {{alphabets[i]}}. {{sub_ques.value}}
                                                            </label>
                                                            <div class="form-check form-check-inline "
                                                                *ngFor="let issue of sub_ques.health_issue_options">
                                                                <input class="form-check-input" type="radio"
                                                                    [attr.disabled]="waiverpersonscovered ? '' : null"
                                                                    [name]="sub_ques.health_issue_id" [value]="issue.id"
                                                                    (change)="medicalHistorySubQues($event, sub_ques)">
                                                                <span class="form-check-label">
                                                                    {{issue.value}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                            </div>
                                        </div>
                                        <ng-container *ngIf="qtns.health_issue.length > 0">
                                            <ng-container *ngFor="let issue of qtns.health_issue">
                                                <div class="col-12" *ngIf="issue.health_issue_id == qtns.selected_val">
                                                    <div class="list-items mb-2"
                                                        *ngIf="!!issue.health_issue_options && issue.health_issue_options.length > 0">
                                                        <div class=" form-check-group"
                                                            *ngFor="let options of issue.health_issue_options">

                                                            <div class="form-check ">
                                                                <ng-container>
                                                                    <div>
                                                                        <ng-container
                                                                            *ngIf="options.field_type == 'checkbox'">
                                                                            <input class="form-check-input"
                                                                                [value]="options.id"
                                                                                type="{{options.field_type}}"
                                                                                [attr.disabled]="waiverpersonscovered ? '' : null"
                                                                                (change)="medicalHealthIssueOptions($event, issue, options)">
                                                                            <label class="form-check-label f-600">
                                                                                {{options.value}} </label>
                                                                        </ng-container>

                                                                    </div>
                                                                    <div class="row mt-2 child-options mb-2">
                                                                        <ng-container
                                                                            *ngFor="let child of options.child">
                                                                            <div class="col-md-6"
                                                                                *ngIf="child.field_type == 'checkbox'">
                                                                                <input class="form-check-input"
                                                                                    type="{{child.field_type}}"
                                                                                    [value]="child.id"
                                                                                    [disabled]="child.disabled"
                                                                                    (change)="medicalHealthIssueOptionsChild($event,issue,options)">
                                                                                <label class="form-check-label">
                                                                                    {{child.value}} </label>
                                                                            </div>
                                                                            <div class="col-md-6 ps-0 mb-1"
                                                                                *ngIf="child.field_type == 'text'">
                                                                                <label class="form-check-label">
                                                                                    {{child.value}}</label>
                                                                                <input type="{{child.field_type}}"
                                                                                    (focusout)="onFocusoutQtns()"
                                                                                    value="" [disabled]="child.disabled"
                                                                                    class="form-control form-control-sm w-80">
                                                                            </div>

                                                                            <div class="col-md-6 ps-0 mb-1"
                                                                                *ngIf="child.field_type == 'date'">
                                                                                <div class="p-float-label">
                                                                                    <p-calendar placeholder=""
                                                                                        [style]="{'width': '80%','display': 'inline-flex'}"
                                                                                        (onSelect)="onSelectQtns()"
                                                                                        formControlName="lifeeventdate"
                                                                                        [monthNavigator]="true"
                                                                                        [yearNavigator]="true"
                                                                                        [minDate]="minimumDate"
                                                                                        [disabledDates]="invalidDates"
                                                                                        [disabled]="child.disabled"
                                                                                        [yearRange]="'1960:' + maxDate.getFullYear()"
                                                                                        [showIcon]="true" inputId="icon">
                                                                                    </p-calendar>
                                                                                    <label class="form-check-label">
                                                                                        {{child.value}}</label>
                                                                                </div>

                                                                            </div>

                                                                        </ng-container>
                                                                    </div>
                                                                </ng-container>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="row "
                                                        *ngIf="!!issue.sub_questions && issue.sub_questions.length > 0">
                                                        <div class="col-md-6 form-group"
                                                            *ngFor="let sub_ques of issue.sub_questions; let i = index">
                                                            <label class="form-label subQ child-label">
                                                                {{alphabets[i]}}. {{sub_ques.value}}
                                                            </label>

                                                            <ng-container
                                                                *ngIf="!!sub_ques.child && sub_ques.child.length > 0">
                                                                <div class="form-check form-check-inline mb-2"
                                                                    *ngFor="let child of sub_ques.child">
                                                                    <input class="form-check-input" type="radio"
                                                                        [name]="sub_ques.id" [value]="child.id"
                                                                        (change)="healthOptionSubQues($event,issue,sub_ques)">
                                                                    <span class="form-check-label">
                                                                        {{child.value}}
                                                                    </span>
                                                                </div>
                                                            </ng-container>

                                                            <ng-container *ngIf="!sub_ques.child">
                                                                <ng-container *ngIf="sub_ques.field_type == 'date'">
                                                                    <div class="p-float-label">
                                                                        <p-calendar placeholder=""
                                                                            (onSelect)="healthOptionDate($event,issue,sub_ques)"
                                                                            [style]="{'display': 'inline-flex','height':'50px'}"
                                                                            formControlName="lifeeventdate"
                                                                            [monthNavigator]="true"
                                                                            [yearNavigator]="true"
                                                                            [minDate]="minimumDate"
                                                                            [disabledDates]="invalidDates"
                                                                            [yearRange]="'1960:' + maxDate.getFullYear()"
                                                                            [showIcon]="true" inputId="icon">
                                                                        </p-calendar>
                                                                        <label for="">Life Event Date</label>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>

                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>

                                        </ng-container>

                                    </div>
                                </div>
                                <div *ngIf="employeedetails">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h6 class="form-title">Details : </h6>
                                        </div>
                                        <div formArrayName="member_medications" class="col-lg-12">
                                            <div class="member-list mb-3"
                                                *ngFor="let spouseForm of member_medications().controls; let empIndex1=index">
                                                <div [formGroupName]="empIndex1" class="border-bottom">
                                                    <h6 class="form-subtitle "> Employee details- {{empIndex1 +1}} </h6>
                                                    <div class="row">
                                                        <div class="col-md-3 form-group has-float-label">
                                                            <input class="form-control" maxlength="25" type="text"
                                                                autocomplete="off" placeholder=" "
                                                                (focusout)="onFocusOutEmployeequestions('empquestion')"
                                                                [attr.disabled]="waiverpersonscovered ? '' : null"
                                                                formControlName="empquestion" id="empquestion">
                                                            <label class="form-label" for="empquestion"> Question
                                                            </label>
                                                        </div>
                                                        <div class="col-md-3 form-group has-float-label">
                                                            <input class="form-control" maxlength="25" type="text"
                                                                autocomplete="off" placeholder=" "
                                                                (focusout)="onFocusOutEmployeequestions('person')"
                                                                [attr.disabled]="waiverpersonscovered ? '' : null"
                                                                formControlName="person" id="person">
                                                            <label class="form-label" for="person"> Person </label>
                                                        </div>
                                                        <div class="col-md-3 form-group has-float-label">
                                                            <input class="form-control" maxlength="25" type="text"
                                                                (focusout)="onFocusOutEmployeequestions('conditiondiagnosis')"
                                                                autocomplete="off" placeholder=" "
                                                                [attr.disabled]="waiverpersonscovered ? '' : null"
                                                                formControlName="conditiondiagnosis"
                                                                id="conditiondiagnosis">
                                                            <label class="form-label" for="conditiondiagnosis">
                                                                Condition/Diagnosis </label>
                                                        </div>
                                                        <div class="col-md-3 form-group">
                                                            <div class="p-float-label">
                                                                <p-calendar placeholder=""
                                                                    [style]="{'width': '100%','display': 'inline-flex','height':'50px'}"
                                                                    (onSelect)="onSelectEmployeedetails()"
                                                                    formControlName="datestreated" [maxDate]="minimumDate"
                                                                    [monthNavigator]="true" [yearNavigator]="true"
                                                                    [yearRange]="'1956:'+ maxDate.getFullYear()"
                                                                    [disabled]="waiverpersonscovered" inputId="navigators"
                                                                    [showIcon]="true" id="datestreated">
                                                                </p-calendar>
                                                                <label class="form-label" for="datestreated">Dates
                                                                    Treated</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4 form-group has-float-label">
                                                            <input class="form-control" maxlength="25" type="text"
                                                                [attr.disabled]="waiverpersonscovered ? '' : null"
                                                                autocomplete="off"
                                                                (focusout)="onFocusOutEmployeequestions('dosage')"
                                                                placeholder=" " formControlName="dosage"
                                                                id="DatesTreated">
                                                            <label class="form-label" for="DatesTreated">
                                                                Treatment including Medications and Dosage
                                                            </label>
                                                        </div>
                                                        <div class="col-md-3 form-group">
                                                            <div class="p-float-label">
                                                                <p-calendar placeholder=" "
                                                                    styleClass="primeclndr-floatlabel"
                                                                    [style]="{'width': '100%','display': 'inline-flex','height':'50px'}"
                                                                    (onSelect)="onSelectEmployeedetails()"
                                                                    formControlName="datelasttaken" [maxDate]="minimumDate"
                                                                    [monthNavigator]="true" [yearNavigator]="true"
                                                                    [yearRange]="'1956:'+ maxDate.getFullYear()"
                                                                    [disabled]="waiverpersonscovered" inputId="navigators"
                                                                    [showIcon]="true">
                                                                </p-calendar>
                                                                <label class="form-label">Date Last Taken</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3 form-group has-float-label">
                                                            <input class="form-control" maxlength="25" type="text"
                                                                (focusout)="onFocusOutEmployeequestions('prognosis')"
                                                                [attr.disabled]="waiverpersonscovered ? '' : null"
                                                                autocomplete="off" placeholder=" "
                                                                formControlName="prognosis" id="prognosis">
                                                            <label class="form-label" for="prognosis"> Prognosis</label>
                                                        </div>
                                                        <div class="col-md-2 form-group text-end" *ngIf="empIndex1>0">
                                                            <button type="submit" class="btn-remove "
                                                                (click)="removeSpouse(empIndex1)"><i
                                                                    class="ri-user-unfollow-line"></i>
                                                                Remove</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="add-members-section mt-2 mb-5">
                                        <div class="button-group">
                                            <button type="submit" class=" btn-add btn-member "
                                                [attr.disabled]="waiverpersonscovered ? '' : null"
                                                (click)="addSpouse()"><i class="ri-user-add-line"></i>
                                                Add </button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div class="terms-container">
                                <div class="form-check">
                                    <input type="checkbox" formControlName="rightscheck" class="form-check-input" id="">
                                    <div class="input-model">
                                        <label class="form-check-label ">NOTICE OF FEDERAL MANDATES
                                            <sup>***</sup>INITIAL
                                            NOTICE ABOUT SPECIAL ENROLLMENT RIGHTS<sup>***</sup>
                                        </label>
                                        <a class="btn btn-link" href="" data-bs-toggle="modal"
                                            data-bs-target="#staticBackdrop">Read More</a>
                                    </div>
                                </div>
                                <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static"
                                    data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel"
                                    aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollabel modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalToggleLabel">Notice of
                                                    Federal
                                                    Mandates</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                    aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <p> If you are declining enrollment for yourself or your dependents
                                                    (including your spouse)
                                                    because of
                                                    other health insurance or group
                                                    health coverage, you may be able to enroll yourself and your
                                                    dependents in this plan if
                                                    you
                                                    or your
                                                    dependents lose eligibility for that
                                                    other coverage (or if the employer stops contributing towards
                                                    your,
                                                    or your dependents’,
                                                    other
                                                    coverage).</p>
                                                <p>
                                                    You must, however, request enrollment within 30 days after you
                                                    or
                                                    your dependents’ other
                                                    coverage
                                                    ends (or after the employer stops
                                                    contributing toward the other coverage).</p>
                                                <p>
                                                    In addition, if you have a new dependent as a result of
                                                    marriage,
                                                    birth, adoption, or
                                                    placement for
                                                    adoption, you may be able to enroll
                                                    yourself and your dependents.</p>
                                                <p>
                                                    Effective April 1, 2009 a federal mandate took effect that
                                                    allows
                                                    for a Special
                                                    Enrollment
                                                    Period,
                                                    which is outlined below.</p>
                                                <p>
                                                    A Special Enrollment Period will be provided for an employee and
                                                    his/her dependent(s)
                                                    who
                                                    are
                                                    eligible, but not enrolled, for coverage
                                                    under the terms of the employer’s plan to enroll for coverage if
                                                    either of the following
                                                    conditions
                                                    are met:
                                                </p>
                                                <p>
                                                    a) The employee or dependent is covered under a Medicaid plan or
                                                    under a State child
                                                    health
                                                    plan and
                                                    coverage of the
                                                    employee or dependent under that plan is terminated as a result
                                                    of
                                                    loss of eligibility
                                                    for
                                                    coverage.
                                                    The request for coverage under the
                                                    employer’s group health plan must be submitted no later than 60
                                                    days
                                                    following the date
                                                    of
                                                    termination of such prior coverage under
                                                    Medicaid or a State child health plan.</p>
                                                <p>
                                                    b) The employee or dependent becomes eligible for assistance
                                                    under a
                                                    Medicaid plan or
                                                    under
                                                    a State
                                                    child health plan. The
                                                    request for coverage under the employer’s group health plan must
                                                    be
                                                    submitted no later
                                                    than
                                                    60 days
                                                    following the date the employee
                                                    or dependent is determined to be eligible for such assistance.
                                                </p>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary"
                                                    data-bs-dismiss="modal">Close</button>
                                                <!-- <button type="button" class="btn btn-primary">Understood</button>// future use -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="terms-container">
                                <div class="form-check">
                                    <input type="checkbox" formControlName="termscheck" name="" class="form-check-input"
                                        id="">
                                    <div class="input-model">
                                        <label class="form-check-label ">APPLICATION Authorization Terms</label>
                                        <button class="btn btn-link" href="" data-bs-toggle="modal"
                                            data-bs-target="#staticBackdrop2">Read More</button>
                                    </div>
                                </div>
                                <div class="modal fade" id="staticBackdrop2" data-bs-backdrop="static"
                                    data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel2"
                                    aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollabel modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title " id="exampleModalToggleLabel">APPLICATION
                                                    Authorization Terms</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                    aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <p>
                                                    I hereby represent that I am an employee of the participating
                                                    employer and that the
                                                    statements and
                                                    answers to the questions on this
                                                    enrollment form are true and complete to the best of my
                                                    knowledge
                                                    and belief. I
                                                    understand
                                                    that the
                                                    statements and answers contained
                                                    herein will be used by The Association Benefits Solutions, LLC,
                                                    marketed and hereinafter
                                                    referred to
                                                    as “Allstate Benefits” to determine
                                                    eligibility for coverage under the Self-Funded Program
                                                    (“Program”)
                                                    for myself and
                                                    persons
                                                    listed on
                                                    this enrollment form as my spouse
                                                    and/or dependent children.</p>
                                                <p>
                                                    I understand and acknowledge that I have elected to participate
                                                    in
                                                    the Section 125 plan
                                                    offered by
                                                    my employer, and I agree that my qualified
                                                    insurance premiums may be paid by my employer through pre-tax
                                                    salary/earnings
                                                    reductions. I
                                                    further
                                                    acknowledge that my Social Security
                                                    contribution and subsequent Social Security benefit will be
                                                    slightly
                                                    reduced.
                                                </p>
                                                <p>
                                                    I understand that (1) the answers given will be the basis of any
                                                    coverage provided;
                                                    (2) any material misrepresentation or failure to provide
                                                    complete
                                                    information to questions on this enrollment form may be used as
                                                    a
                                                    basis for
                                                    changing rates or terminating coverage;
                                                    (3) if coverage is not approved, I, my spouse and/or dependent
                                                    children are not entitled
                                                    to
                                                    benefits; (4) if I, my spouse and/or dependent
                                                    children waive coverage and decide to apply for coverage at a
                                                    later
                                                    date, evidence of
                                                    eligibility
                                                    may be required and benefits may be
                                                    deferred for a specified period of time; and (5) coverage will
                                                    not
                                                    be effective until my
                                                    employer
                                                    receives notice that this enrollment form
                                                    has been approved by Allstate Benefits.
                                                </p>
                                                <p>
                                                    I hereby authorize any licensed physician, medical practitioner,
                                                    hospital, clinic or
                                                    other
                                                    medical
                                                    or medically-related facility, insurance
                                                    company, pharmacy or pharmacy-related entity, pharmacy benefits
                                                    manager (PBM) or
                                                    PBM-related
                                                    entity,
                                                    insurance or reinsurance
                                                    company or employer, having information about me or my minor
                                                    children to provide all
                                                    such
                                                    information as may be requested to Allstate
                                                    Benefits, its legal representative or any medical records
                                                    retrieval
                                                    service Allstate
                                                    Benefits may
                                                    engage.
                                                </p>
                                                <p>
                                                    This authorization includes any and all information any of the
                                                    foregoing may have about
                                                    me,
                                                    including, but not limited to, information
                                                    regarding diagnosis, testing, treatment and prognosis of my
                                                    physical
                                                    or mental condition
                                                    as
                                                    well as
                                                    alcohol abuse treatment, drug abuse
                                                    treatment, psychiatric treatment, pharmacy prescriptions, HIV
                                                    testing and treatment, STD
                                                    testing and
                                                    treatment, sickle cell testing and
                                                    treatment, lab data and EKGs. This information may also be
                                                    disclosed
                                                    to any medical
                                                    records
                                                    company
                                                    engaged by Allstate Benefits.
                                                    Although federal regulation requires that we inform you of the
                                                    potential that
                                                    information
                                                    disclosed
                                                    pursuant to this authorization may be
                                                    subject to redisclosure by the recipient and no longer be
                                                    protected
                                                    by such regulation,
                                                    all
                                                    information received by Allstate Benefits
                                                    pursuant to this authorization will be protected by federal and
                                                    state privacy laws and
                                                    regulations.
                                                </p>
                                                <p>
                                                    I understand and agree that in connection with my application
                                                    for
                                                    coverage under the
                                                    Program: (1)
                                                    Allstate Benefits may obtain consumer
                                                    reports which may include credit information, a driver history
                                                    report, and/or personal
                                                    or
                                                    privileged
                                                    information from third parties; (2) such
                                                    information may be disclosed to affiliated or unaffiliated third
                                                    parties without my
                                                    prior
                                                    permission
                                                    but only as permitted or required by law;
                                                    (3) upon my written request, Allstate Benefits will inform me if
                                                    a
                                                    consumer report was
                                                    requested and
                                                    the name and address of the
                                                    consumer reporting agency that furnished the report; (4) I may
                                                    also
                                                    request access to
                                                    and
                                                    correction
                                                    of information Allstate Benefits has
                                                    collected on me; (5) Allstate Benefits may request and use
                                                    subsequent consumer reports
                                                    in
                                                    updating
                                                    and renewing any insurance or
                                                    health coverage afforded in connection with this Application;
                                                    and
                                                    (6) Allstate Benefits
                                                    will
                                                    furnish
                                                    a more detailed explanation of its
                                                    information practices upon my request.
                                                </p>
                                                <p>
                                                    In connection with this application for health plan coverage,
                                                    Allstate Benefits will
                                                    review
                                                    my
                                                    credit report or obtain or use an insurance
                                                    credit score based on the information contained in that credit
                                                    report. Allstate Benefits
                                                    may
                                                    use a
                                                    third party in connection with the
                                                    development of my insurance credit score. I may request that my
                                                    credit information be
                                                    updated and if
                                                    I question the accuracy of the credit
                                                    information, Allstate Benefits will, upon my request, reevaluate
                                                    me
                                                    based on corrected
                                                    credit
                                                    information from a consumer reporting
                                                    agency. I hereby authorize Allstate Benefits to obtain consumer
                                                    reports on me.</p>
                                                <p>
                                                    I understand that this authorization is required in order to
                                                    enable
                                                    Allstate Benefits to
                                                    make
                                                    eligibility or enrollment determinations relating
                                                    to me, my spouse and/or my dependents or for Allstate Benefits
                                                    to
                                                    make underwriting or
                                                    risk
                                                    rating
                                                    determinations. If I refuse to sign or
                                                    revoke this authorization, or refuse to authorize Allstate
                                                    Benefits
                                                    to obtain a consumer
                                                    report on
                                                    me, Allstate Benefits may refuse to
                                                    consider my application for enrollment.
                                                </p>
                                                <p>
                                                    I understand that I may revoke this authorization at any time by
                                                    notifying Allstate
                                                    Benefits
                                                    in
                                                    writing of my desire to revoke. Such
                                                    revocation must be sent by certified mail to the following
                                                    address:
                                                    Privacy Office,
                                                    National
                                                    Health
                                                    Insurance Company, 4455 LBJ
                                                    Freeway, Ste 375, Dallas, TX 75244. Such revocation will not be
                                                    valid to the extent
                                                    Allstate
                                                    Benefits has taken action in reliance on the
                                                    authorization prior to its revocation. This authorization
                                                    expires
                                                    upon the earliest of
                                                    the
                                                    following: denial of my application, declination of
                                                    enrollment, or when I am no longer covered under the Program,
                                                    but in
                                                    no event will this
                                                    authorization be in effect for longer than 24
                                                    months from the date signed.</p>
                                                <p>
                                                    I acknowledge that knowing and willful misstatements in this
                                                    enrollment form may
                                                    constitute
                                                    health
                                                    care fraud, a criminal violation of 18
                                                    US Code Section 1347 (punishable by up to 10 years in prison).
                                                </p>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary"
                                                    data-bs-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="row"> // future use
                    <div class="col-lg-12">
                        <div class="dependent-card section-notapplicable"
                            [ngClass]="{'section-notapplicable': waiverpersonscovered, 'section-applicable': waiverreasonedit}">
                            <div class="row">
                                <div class="col-md-4 form-group">
                                    <label for="" class="form-label">Employee/Primary Applicant Signature:</label>
                                    <input type="text" formControlName="applicant_signature" autocomplete="off"
                                        [attr.disabled]="waiverpersonscovered ? '' : null" class="form-control"
                                        placeholder="Signature">
                                </div>
                                <div class="col-md-5"></div>
                                <div class="col-md-3 form-group">
                                    <label for="" class="form-label"> Date :</label>
                                    <input class="form-control signaturedate" placeholder="date"
                                        [attr.disabled]="waiverpersonscovered ? '' : null"
                                        value="{{date | date:'MM/dd/yyyy'}}" />

                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-12 form-group dependent-card">
                    <h6 class="title-hr">
                        <span>Signature</span>
                    </h6>


                    <ul class="nav nav-tabs" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="home-tab" data-bs-toggle="tab"
                                data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane"
                                aria-selected="true" (click)="onTabClick('esignature')">
                                Draw
                            </button>
                        </li>
                        <!-- <li class="nav-item" role="presentation">  // future use
                            <button class="nav-link" id="profile-tab" data-bs-toggle="tab"
                                data-bs-target="#profile-tab-pane" type="button" role="tab"
                                aria-controls="profile-tab-pane" aria-selected="false"
                                (click)="onTabClick('text')">Text</button>
                        </li> -->
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="image-tab" data-bs-toggle="tab"
                                data-bs-target="#image-tab-pane" type="button" role="tab" aria-controls="image-tab-pane"
                                aria-selected="false" (click)="onTabClick('image')">
                                Upload</button>
                        </li>
                    </ul>

                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel"
                            aria-labelledby="home-tab" tabindex="0">

                            <div class="row">
                                <div class="col-12">
                                    <canvas #canvas height="200" width="600" (click)="signatureField('signatureValid')"
                                        class="sigPad">
                                    </canvas>
                                </div>
                                <div class="col-6">
                                    <p class="mt-2">{{est_date}}</p>
                                </div>
                                <div class="col-6 text-end">
                                    <button type="button" class="btn btn-outline-danger"
                                        (click)="clear()">Clear</button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab"
                            tabindex="0">
                            <div class="row">
                                <div class="col-md-6 mt-2">
                                    By : <input placeholder="signature" class="form-control" formControlName="enterText"
                                        (focusout)="onfocusoutSignature()" id="signature" class="input" type="text"
                                        style="font-size: 15px;
                                    font-style: italic; " />
                                    <div>
                                        <div style="font-size: 70%;margin-left: 30px;">Signature of
                                            Primary
                                            Applicant <span class="fieldMan">*</span></div>
                                    </div>
                                </div>

                                <div class="col-md-6 ">
                                    Date : <input class="input" class="form-control" style="width: 28%"
                                        placeholder="date" value="{{date | date:'MM/dd/yyyy'}}" />
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade uploadimage mt-3" id="image-tab-pane" role="tabpanel"
                            aria-labelledby="image-tab" tabindex="0">
                            <input type="file" multiple="multiple" style="width: 40%" formControlName="uploadImage"
                                accept=".doc,.pdf,.csv,.docx,application/msword,image/png, image/jpeg" name="uploadFile"
                                (change)="upload($event)" class="form-control" id="uploadFIle">
                            <div class="mt-4">
                                {{est_date}}
                            </div>
                        </div>

                    </div>
                    <div *ngIf="message">
                        <small class="errormsg">
                            {{message}}
                        </small>
                    </div>
                    <div *ngIf="uploadedFile " class="mt-2">
                        <img *ngIf="imageUrl" [src]="imageUrl" class="displayImage">
                    </div>
                </div>
                <div class="row mt-3 mb-3">
                    <div class="col-lg-10"></div>
                    <div class="row mt-3 mb-3">
                        <div class="col-lg-12 text-end">
                            <button class="btn-getprice" (click)="finalSubmit(form.value)">Submit</button>
                        </div>
                    </div>               
                </div>
            </form>
        </div>
        <br>
    </div>
</section>
<div class="loader" *ngIf="loader">
    <div id="loading"></div>
</div>
