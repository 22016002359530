import { Component, OnInit } from '@angular/core';
import { TestService } from '../services/behaviourService';
import { SharingService } from '../services/sharing.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AgencyAgentService } from '../services/agency-agent-service';
import { SessionService } from '../services/session.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    url: string;
    companyId: string;
    companyInfo: any;
    logo: any;
    MemberLogin: any;
    header_array: any[]=[];
    footer_array: any;
    disclaimer_array: any;
    companyName: any;
    resource_plans: any;
    routepath: string;
    plans: any;
    disclaimer: any;
    supportEmail: any;
    supportPhone: any;
    template: any;
    display_enrollment: boolean;
    public_url: any;
    groupLogo: any;
    displayHeaderSection: boolean = false;
    tierName: any = '';
    affiliateCode: any;
    utm_affCode: any;

    isDemoPortal: boolean = false;
    isAgent: boolean = false;

    agencyDetails: any;
    agentDetails: any;

    queryParamPresent: boolean = false;
    paramsContainproduct_prices_id: boolean = false;
    showText: any;
    enrollmentText: any;
    routeQueryParams: any;
    isReferralMemberRoute: boolean = false;
    agency_logo: any = null;
    cssUrl: any;

    constructor(
        private behaviourservice: TestService,
        private service: SharingService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private agencyDetailService: AgencyAgentService,
        private sessionService: SessionService,
        private sanitizer: DomSanitizer,

    ) { 
        this.sessionService.setData('is_agency_in_url', false);
        this.sessionService.setData('agency_company_id', '');
        this.sessionService.setData('agent_id', '');
        
        const agencyAgentPaths = ['agency', 'agent'];
        const url = window.location.pathname;
        const path = url.split('/');
        if (agencyAgentPaths[0] === path[1]) {
            const agencyCompanyIdFromUrl = (path[2]) ? path[2] : null;
            if(!agencyCompanyIdFromUrl) {
                this.router.navigate(['/404']);
            }

            this.sessionService.setData('is_agency_in_url', true);
            this.sessionService.setData('agency_company_id', agencyCompanyIdFromUrl);

            if(agencyAgentPaths[1] === path[3]) {
                const agentId = (path[4]) ? path[4] : null;
                if(!agentId) {
                    this.router.navigate(['/404']);
                }

                this.sessionService.setData('agent_id', agentId);
            }
        }
        else if(agencyAgentPaths[1] === path[1]) {
            const agentId = (path[1]) ? path[2] : null;
            if(!agentId) {
                this.router.navigate(['/404']);
            }

            this.sessionService.setData('agent_id', agentId);
        }
        else {
            this.sessionService.setData('is_agency_in_url', false);
            this.sessionService.setData('agency_company_id', '');
            this.sessionService.setData('agent_id', '');
        }

        this.service.clientData.subscribe((data) => {
            if (data !== '') {
                this.companyInfo = data;
                this.companyId = this.companyInfo.company_id;
                this.logo = data.apiURL.slice(0, -4) + data.companyLogo;
                this.companyName = this.companyInfo.company_name;
                this.isDemoPortal = this.companyInfo.demo_portal === '1' ? true : false; //Boolean to use only for the demo portal(s)
                this.MemberLogin = this.companyInfo.MemberLogin;
                this.public_url = data.public_url;
                this.template = data?.template;
                
                const agency_company_id = this.sessionService.getData('agency_company_id');
                const agentId = this.sessionService.getData('agent_id');
                if(this.isHomePageUrl() || agency_company_id  || agentId){
                    this.template = 'home_page';
                    if(agency_company_id) {
                        this.getAgencyDetails(agency_company_id);
                    }
                    else {
                        this.sessionService.setData('agency_company_id', this.companyId);
                        this.getAgencyDetails(this.companyId);
                    }

                    if(agentId) {
                        this.getAgentDetails(agentId);
                    }
                }

                if (this.template !== 'template_2' && this.template !== 'package' && this.template !== 'home_page') {
                    this.getMenu();
                }
                this.supportEmail = data?.support_email;
                this.supportPhone = data?.support_phone;
                localStorage.removeItem('social_media');
            }
        });

        if (window.location.href.includes('affiliate')) {
            this.display_enrollment = false
        } else {
            this.display_enrollment = false;
        }
        if (window.location.href.includes('/MemberEnrollment/')) {
            this.displayHeaderSection = true;
        }
        if (window.location.href.includes('/addgroup/')) {
            this.displayHeaderSection = true;
        } else {
            this.displayHeaderSection = false;
        }

        this.service.agencyLogo$.subscribe((logo) => {
            this.agency_logo = logo;
        });
    }

    ngOnInit(): void {
        if(window.location.origin.includes('bestpolicy'))
            {
                this.cssUrl = this.sanitizer.bypassSecurityTrustResourceUrl('assets/css/bestpolicy.css');
            }
        // Listen to route changes
        this.router.events.subscribe(event => {
            // Listen to route changes
            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    // Use `urlAfterRedirects` to check the final URL, and use `includes` to handle query params or fragments
                    this.isReferralMemberRoute = event.urlAfterRedirects.includes('/referral/member');
                }
            });
            
            // Initial check in case the user arrives directly on the route
            const currentUrl = this.router.url;
            this.isReferralMemberRoute = currentUrl.includes('/referral/member');            
        });

        setTimeout(() => {
            const firstChild = this.activatedRoute.firstChild;
            if (firstChild) {
                firstChild.url.subscribe(urlSegment => {
                    if (urlSegment.length > 0) {
                        this.activatedRoute.queryParams.subscribe(params => {
                            this.tierName = params?.tier_name;
                            this.routeQueryParams = params;
                        });
                        console.log(urlSegment);
                        if (urlSegment[0]?.path == 'affiliate') {
                            this.showText = 'Affiliate Appointment';
                        }
                        else if (urlSegment[0]?.path == 'DirectMemberEnrollment' && Object.keys(this.routeQueryParams).length > 0) {
                            localStorage.setItem('queryparams', 'present');
                            const queryParamKeys = Object.keys(this.routeQueryParams);  // Get query param keys
                            if ((queryParamKeys[0] == 'product_prices_id' || queryParamKeys[0] == 'affiliate_code')) {
                                this.showText = 'Affiliate Assisted Enrollment';
                                if (queryParamKeys[0] == 'affiliate_code') {
                                    localStorage.setItem('checkenrollment', 'affiliate_code');
                                }
                                if (queryParamKeys[0] == 'product_prices_id') {
                                    localStorage.setItem('checkenrollment', 'product_prices_id');

                                }
                            }
                            else if (queryParamKeys[0] == 'product_name') {
                                localStorage.setItem('checkenrollment', 'product_name');
                                this.showText = 'Member Enrollment'
                            }

                        }
                        else if (urlSegment[0]?.path == 'package' || urlSegment[0]?.path == 'order-confirmation'
                        ) {
                            if (urlSegment[0]?.path == 'package') {
                                let checkenrollment = localStorage.getItem('checkenrollment');
                                if (checkenrollment == 'product_name') {
                                    this.showText = 'Member Enrollment';
                                }
                                if (checkenrollment == 'product_prices_id' || checkenrollment == 'affiliate_code') {
                                    this.showText = 'Affiliate Assisted Enrollment';
                                }
                                if(checkenrollment =='')
                                {
                                    this.showText = 'Member Enrollment';
                                }
                                //    this.showText = checkenrollment != 'product_name' ? '' : ''

                            }
                            else if (urlSegment[0]?.path == 'order-confirmation')
                            {
                                let checkenrollment = localStorage.getItem('checkenrollment');
                                if (  checkenrollment == 'affiliate_code') {
                                    this.showText = 'Affiliate Assisted Enrollment';
                                }
                                else{
                                    this.showText = 'Member Enrollment';
                                }
                            }
                            // this.showText = 'Affiliate Assisted Enrollment'
                        }
                        else if (urlSegment[0]?.path == 'products'
                            || urlSegment[0]?.path == 'payments' || urlSegment[0]?.path == 'beneficiary'
                            || urlSegment[0]?.path == 'agrement' || urlSegment[0]?.path == 'disclosure'
                            || urlSegment[0]?.path == 'disclosure_two' || urlSegment[0]?.path == 'membersummary'
                            || urlSegment[0]?.path == 'confirmation') {
                            let queryparamsCheck = localStorage.getItem('queryparams');
                            this.showText = queryparamsCheck == 'present' ? 'Affiliate Assisted Enrollment' : 'Member Enrollment'

                        }
                        else if (urlSegment[0]?.path == 'DirectMemberEnrollment' && Object.keys(this.routeQueryParams).length == 0) {
                            localStorage.setItem('queryparams', 'notpresent');
                            localStorage.setItem('checkenrollment', '');

                            this.showText = 'Member Enrollment';
                        }


                    }
                });
            }

        }, 700);

        this.service.grouplogo.subscribe((data: any) => {
            if (data != '') {
                this.groupLogo = data;
            }
        })

        
    }

    navigateToPublic() {
        if (!!this.public_url) {
            window.open(this.public_url, '_blank');
        }
    }

    getMenu(): void {
        var url = 'GetClientInfo';
        let body = {
            company_id: this.companyId
        }
        this.service.postData(url, body).subscribe((Object: any) => {
            if (Object.status) {
                if (Object.data) {
                    this.header_array = Object.data[0]?.headers[0]?.menus;
                    this.resource_plans = Object.data[0]?.headers[1]?.resource_plans;
                    this.disclaimer = Object.data[0]?.headers[2]?.disclaimer;

                    this.footer_array = Object.data[1]?.footer[0]?.social_media;
                    this.disclaimer_array = Object.data[1]?.footer[1]?.disclaimer;
                    localStorage.setItem('disclaimer', JSON.stringify(this.disclaimer_array))
                    localStorage.setItem('social_media', JSON.stringify(this.footer_array))
                    this.behaviourservice.pushClientInfo(Object.data)
                }
            }
        }, err => {
            console.log(err)
        })
    }

    route(value) {
        if (value == 'HOME') {
            this.routepath = 'https://hopehealthshare.com'
        } else if (value == 'PROGRAMS' && origin.includes('hope')) {
            this.routepath = 'https://hopehealthshare.com/programs/'
        } else if (value == 'ABOUT') {
            this.routepath = 'https://hopehealthshare.com/about/'
        } else if (value == 'FAQ') {
            this.routepath = 'https://hopehealthshare.com/faq/'
        } else if (value == 'CONTACT US' && origin.includes('hope')) {
            this.routepath = 'https://hopehealthshare.com/contact/'
        } else if (value == 'WHO WE ARE') {
            this.routepath = 'https://www.joppahealth.org/who-we-are/'
        } else if (value == 'HOW IT WORKS') {
            this.routepath = 'https://www.joppahealth.org/how-it-works/'
        } else if (value == 'PROGRAMS') {
            this.routepath = 'https://www.joppahealth.org/programs/ '
        } else if (value == 'CONTACT US') {
            this.routepath = 'https://www.joppahealth.org/contact/'
        } else if (value == 'STATE NOTICES') {
            this.routepath = 'https://www.joppahealth.org/state-specific-notices/'
        }


    }

    Brochure(value) {
        if (value == 'Hope Health Brochure Plus') {
            this.plans = 'https://thedivinitygroup.com/Hope-Health-Brochure-Plus.pdf'
        } else if (value == 'Hope Health Guide Plus') {
            this.plans = 'https://thedivinitygroup.com/Hope-Health-Guide-Plus.pdf'
        } else if (value == 'Hope Health Brochure Premium') {
            this.plans = 'https://thedivinitygroup.com/Hope-Health-Brochure-Premium.pdf'
        } else if (value == 'Hope Health Guide Premium') {
            this.plans = 'https://thedivinitygroup.com/Hope-Health-Guide-Premium.pdf'
        }

    }

    getAgencyDetails(agencyId) {
        var url = `GetAgencyDetails/${agencyId}`;
        this.service.getData(url).subscribe((Object: any) => {
            if (Object.status) {
                if (Object.data) {
                    this.agencyDetails = Object.data;
                    this.agencyDetailService.setAgencyDetail(this.agencyDetails);
                }
            }

            if (!this.agencyDetails) {
                this.router.navigate(['/404']);
            }
        }, err => {
            console.log(err);
            this.router.navigate(['/404']);
        })
    }

    getAgentDetails(agentId) {
        var url = `GetAgent/${agentId}`;
        this.service.getData(url).subscribe((Object: any) => {
            if (Object.status) {
                if (Object.data) {
                    this.agentDetails = Object.data;
                    this.agencyDetailService.setAgentDetail(this.agentDetails);
                }
            }

            if (!this.agentDetails) {
                this.router.navigate(['/404']);
            }
        }, err => {
            console.log(err);
            this.router.navigate(['/404']);
        })
    }

    isHomePageUrl() {
        try {
            const parsedUrl = new URL(window.location.href);
            return parsedUrl.pathname === "/" || parsedUrl.pathname === "";
        } catch (error) {
            return false;
        }
    }
}
