import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SharingService} from '../../../services/sharing.service';
import html2pdf from 'html2pdf.js'
import {TestService} from '../../../services/behaviourService';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
    selector: 'app-thankyou',
    templateUrl: './thankyou.component.html',
    styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent implements OnInit {
    @ViewChild('orderInvoice') orderInvoiceElement: ElementRef;
    retrievedOrderDetails: any;
    retrievedOrderMembers: any;
    orderDetails: any[] = [];
    orderMembers: any[] = [];
    cartData: any[] = [];
    cartProducts: any[] = [];
    paymentData: any[] = [];
    cartFee: any;
    memberDiscount: any;
    expdate: any;
    year: any;
    month: any;
    companyInfo: any;
    oneTimeFee: any;
    a2COneTimeFee: any;
    groupInfo: any;
    enrolmantType: any;
    companyInvoice: any;
    companyId: any;
    monthlyContribution: any;
    monthlyContributionStartDate: any;
    cart_addon_products: any;
    plannames: string;
    companyname: any;
    monthly_contribution_day: any;
    application_id: any;
    pdfobj: any;
    cartDiscount: any;
    startDate: any;
    isDemoPortal: boolean = false;

    constructor(private sharingService: SharingService, private services: SharingService, private objectService: TestService) {
        this.sharingService.clientData.subscribe((data) => {
            if (data != '') {
                this.companyInfo = data;
                this.companyId = this.companyInfo.company_id;
                this.companyname = this.companyInfo.company_name;
                this.companyInvoice = this.companyInfo.companyUrl;
                if(this.companyInfo?.agency_level_commission === "1"){
                    this.isDemoPortal = this.companyInfo?.demo_portal === '1' ? false : true; //Boolean to use only for the demo portal(s)
                }else{
                this.isDemoPortal = this.companyInfo?.demo_portal === '1' ? true : false; //Boolean to use only for the demo portal(s)
                }
            }

        })
        var applicantData = JSON.parse(localStorage.getItem('appCart'));
        if (applicantData != null) {
            this.cartFee = applicantData.cart_fee;
            this.cartData = applicantData.cart_data;
            this.memberDiscount = applicantData.agent_cart_discount;
            this.oneTimeFee = applicantData.a2c_total_one_time_fee;
            this.cartProducts = applicantData.cart_products;
            if (this.cartProducts.length != 0) {
                if (this.cartProducts.length > 1) {
                    this.plannames = this.cartProducts[0].name + ' ' + ', ' + this.cartProducts[1].name;
                } else {
                    this.plannames = this.cartProducts[0].name;
                }
            }
            this.monthlyContribution = applicantData.monthly_contribution;
            this.startDate = applicantData.member_applications_data[0].startdate
            this.monthlyContributionStartDate = applicantData.member_applications_data[0].monthly_contribution_start_date;
            this.monthly_contribution_day = applicantData.member_applications_data[0].monthly_contribution_day
            this.application_id = applicantData.member_applications_data[0].id
            this.a2COneTimeFee = applicantData.total_one_time_fee;
        }
        this.objectService.appcartData.subscribe((data) => {
            if (data != '') {
                this.cartFee = data.cart_fee;
                this.cartData = data.cart_data;
                this.cartProducts = data.cart_products;
                if (this.cartProducts.length != 0) {
                    if (this.cartProducts.length > 1) {
                        this.plannames = this.cartProducts[0].name + ', ' + ' ' + this.cartProducts[1].name;
                    } else {
                        this.plannames = this.cartProducts[0].name;
                    }
                }
                this.monthlyContribution = data.monthly_contribution;
                this.startDate = data.member_applications_data[0].startdate
                this.monthlyContributionStartDate = data.member_applications_data[0].monthly_contribution_start_date;
                this.monthly_contribution_day = data.member_applications_data[0].monthly_contribution_day
                this.application_id = data.member_applications_data[0].id
                this.oneTimeFee = data.total_one_time_fee;
            }
        })
    }

    ngOnInit(): void {
        let data = this.sharingService.getAgentGroupInfo();//G2C flow
        if (data != "" && data != undefined && data != null) {
            if (data[0].groupID != null || data[0].groupID != undefined) {
                this.groupInfo = data;
            }
        }
        this.retrievedOrderDetails = this.sharingService.getOrderDetails();
        this.cartDiscount = this.retrievedOrderDetails.cart_group_discount;
        this.enrolmantType = this.retrievedOrderDetails.enrollment_type;
        this.orderDetails = this.retrievedOrderDetails.order_details;
        this.paymentData = this.retrievedOrderDetails.paymentdata;
        this.memberDiscount = this.retrievedOrderDetails.agent_cart_discount;
        this.a2COneTimeFee = this.retrievedOrderDetails.total_one_time_fee;
        this.cart_addon_products = this.retrievedOrderDetails.cart_addon_products;
        this.retrievedOrderMembers = this.sharingService.getOrderMembers();
        this.orderMembers = this.retrievedOrderMembers.order_members;
        this.objectService.demoToA2c("");
        setTimeout(() => {
            this.email()
        }, 100);

    }

    //   downloadSectionAsPDF() {
    //   const data = document.getElementById('order-invoice');
    //   html2canvas(data, { scrollY: -window.scrollY, windowWidth: data.offsetWidth })
    //     .then(canvas => {
    //       const imgData = canvas.toDataURL('image/png');

    //       // Dimensions of an A4 page in mm
    //       const pdfWidth = 210;
    //       const pdfHeight = 297;
    //       const imgWidth = 208; // Slightly less than A4 width to add margins
    //       const imgHeight = canvas.height * imgWidth / canvas.width;

    //       // Create a new PDF
    //       let pdf = new jsPDF('p', 'mm', 'a4');

    //       // Calculate the number of pages.
    //       let numberOfPages = Math.ceil(imgHeight / pdfHeight);
    //       for (let i = 0; i < numberOfPages; i++) {
    //         // Add new page if not the first page
    //         if (i > 0) {
    //           pdf.addPage();
    //         }
    //         // Calculate the y coordinate to slice the image
    //         const y = i * pdfHeight;
    //         pdf.addImage(imgData, 'PNG', 0, -y, imgWidth, imgHeight);
    //       }

    //       pdf.save('Order_Receipt.pdf');
    //     });
    // }


    downloadSectionAsPDF() {
        const data = this.orderInvoiceElement.nativeElement;

        // Clone the element and apply A4 size styling
        const clonedData = data.cloneNode(true) as HTMLElement;
        clonedData.style.width = '210mm';
        clonedData.style.height = '297mm';
        clonedData.style.overflow = 'hidden';
        clonedData.style.position = 'fixed';
        clonedData.style.left = '-10000px'; // Position off-screen

        document.body.appendChild(clonedData);

        html2canvas(clonedData, {scale: 2, useCORS: true}).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 190; // Slightly less than A4 width to add margins
            const imgHeight = canvas.height * imgWidth / canvas.width;

            // Add the image to the PDF
            pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);

            //Generate a date and time string for the filename
            const now = new Date();
            const dateString = formatDateToString(now); // Use the helper function to format the date

            pdf.save(`Order_Receipt_${dateString}.pdf`);

            // format the date as MMDDYYYYHHMMSS
            function formatDateToString(date) {
                const pad = (num) => (num < 10 ? '0' + num : num.toString());
                const month = pad(date.getMonth() + 1); // getMonth() is zero-based
                const day = pad(date.getDate());
                const year = date.getFullYear();
                const hours = pad(date.getHours());
                const minutes = pad(date.getMinutes());
                const seconds = pad(date.getSeconds());

                return `${month}${day}${year}${hours}${minutes}${seconds}`;
            }

            // Clean up: remove the cloned element
            document.body.removeChild(clonedData);
        }).catch(error => {
            console.error('Error generating PDF:', error);
        });
    }


    email() {
        let clone = document.getElementById('order-invoice').cloneNode(true) as HTMLElement;
        let opt = {
            margin: [0.5, 0],
            filename: 'Divinity Marketing Agreement - Unlicensed.pdf',
            image: {type: 'jpeg', quality: 0.98},
            html2canvas: {scale: 2, logging: true, dpi: 192, letterRendering: true},
            jsPDF: {unit: 'in', orientation: 'portrait', compress: true, lineHeight: 1, format: 'a4'}
        };
        html2pdf().set(opt).from(clone).outputPdf('arraybuffer').then(pdfAsArrayBuffer => {
            const pdfBlob = new Blob([pdfAsArrayBuffer], {type: 'application/pdf'});
            const fileName = 'Divinity Marketing Agreement - Unlicensed.pdf';
            const formData = new FormData();
            formData.append("family_id", this.orderDetails[0].family_id);
            formData.append("member_id", this.orderDetails[0].member_id);
            formData.append("order_id", this.orderDetails[0].order_id);
            formData.append("company_id", this.companyId);
            formData.append('nicotine', localStorage.getItem('nicotineselected'));
            formData.append("receipt", pdfBlob, 'Receipt.pdf');// here we need to send the file.
            var url = 'UploadReceipt';
            this.services.postData(url, formData).subscribe((Object: any) => {
            }, err => {
                console.log(err);
                //  this.toastr.error(err.message);
            })
            //  Download the PDF //can be used for testing
            // const link = document.createElement('a');
            // link.href = URL.createObjectURL(pdfBlob);
            // link.download = 'Receipt.pdf';
            // link.click();

        })
    }

}
